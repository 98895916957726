import { useTheme } from "@mui/material";
import { SnackbarProvider } from "notistack";

import { WarningIcon } from "components/atoms";

import { WarningErrorStatus } from "./WarningErrorStatus";

type WarningErrorsParentProps = {
  warnings: string[];
  errors: string[];
};

export const WarningErrorsParent = ({
  warnings,
  errors,
}: WarningErrorsParentProps) => {
  const theme = useTheme();

  return (
    <SnackbarProvider
      domRoot={document.getElementById("#diagram-canvas") as HTMLElement}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      preventDuplicate
      dense
      iconVariant={{
        error: (
          <WarningIcon
            color={theme.palette.error.contrastText}
            style={iconStyle}
          />
        ),
        warning: (
          <WarningIcon
            color={theme.palette.warning.contrastText}
            style={iconStyle}
          />
        ),
      }}
    >
      <WarningErrorStatus warnings={warnings} errors={errors} />
    </SnackbarProvider>
  );
};

const iconStyle = { height: "20", width: "20", marginRight: "8px" };
