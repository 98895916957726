import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import PersonAdd from "@mui/icons-material/PersonAddRounded";
import { Box, Chip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useParams, useHistory } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import {
  useOrganizationUserDelete,
  useOrganizationUserUpdate,
} from "libs/data/customized/organization";
import { useOrganizationUsersList } from "libs/data/endpoints/organizations/organizations";
import { useUserGet } from "libs/data/endpoints/user/user";
import { useGoogleAnalytics } from "libs/hooks";
import { secretValues } from "libs/utilities/labels-mapping";
import { useGetCurrentOrganization } from "store/features";

import {
  DeleteDialog,
  HighlightedText,
  IconButton,
  OverflowTooltip,
  PrimaryButton,
} from "components/atoms";
import { BaseTable, PageContainer } from "components/molecules";

const OrganizationUsersOverview = () => {
  useGoogleAnalytics();
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [editRowId, setEditRowId] = useState(undefined);
  const [editedRow, setEditedRow] = useState(undefined);

  const { organizationName } = useParams();
  const { data: user } = useUserGet();
  const { data: users, error } = useOrganizationUsersList(organizationName);
  const usersLoading = !users && !error;

  const currentOrganization = useGetCurrentOrganization();

  const updateOrganizationUser = useOrganizationUserUpdate(organizationName);
  const deleteOrganizationUser = useOrganizationUserDelete(organizationName);

  const match = useRouteMatch();
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        title: "Email",
        field: "email",
        defaultSort: "asc",
        nowrap: true,
        render: ({ email }) => {
          return email === user?.email ? (
            <div display="flex" style={{ flexDirection: "column" }}>
              <HighlightedText>You</HighlightedText>
              <OverflowTooltip
                title={<Typography variant="h5">{email}</Typography>}
              >
                {email}
              </OverflowTooltip>
            </div>
          ) : (
            email
          );
        },
      },
      {
        title: "First name",
        field: "name",
        nowrap: true,
      },
      {
        title: "Last name",
        field: "surname",
        nowrap: true,
      },
      {
        title: "Admin",
        field: "admin",
        editable: "always",
        lookup: secretValues,
        width: "15%",
        initialEditValue: false,
        options: Object.entries(secretValues).map(([value, label]) => ({
          label,
          value,
        })),
        render: (rowData) => (
          <>
            {rowData.admin && (
              <Chip variant="outlined" size="small" label="Admin" />
            )}
          </>
        ),
      },
      {
        title: "Status",
        field: "status",
        hidden: users?.findIndex((user) => user.status === "invited") === -1,
        render: (rowData) => (
          <>
            {rowData?.status === "invited" && (
              <Chip variant="outlined" size="small" label="Invited" />
            )}
          </>
        ),
      },
      {
        disableClick: true,
        width: "15%",
        align: "right",

        render: (rowData) => (
          <Box display="flex" justifyContent="flex-end">
            {editRowId === rowData?.id ? (
              <>
                <IconButton
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={async () => {
                    await updateOrganizationUser(rowData.id, editedRow);
                    setEditRowId(undefined);
                    setEditedRow(undefined);
                  }}
                  icon={CheckIcon}
                />
                <IconButton
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={() => {
                    setEditRowId(undefined);
                    setEditedRow(undefined);
                  }}
                  icon={CloseIcon}
                />
              </>
            ) : (
              <>
                <IconButton
                  icon={Edit}
                  htmlColor="secondary"
                  hoverColor="secondary"
                  tooltip="Edit"
                  onClick={() => {
                    setEditedRow(rowData);
                    setEditRowId(rowData.id);
                  }}
                />
                <IconButton
                  icon={Delete}
                  tooltip="Remove device"
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={() => {
                    setIsDeleteDialogOpen(true);
                    setRowToDelete(rowData);
                  }}
                />
              </>
            )}
          </Box>
        ),
      },
    ],
    [editRowId, editedRow, updateOrganizationUser, user?.email, users]
  );

  return (
    <PageContainer>
      <PageHeader title={organizationName} />
      <BreadcrumbsItem to={match.url}>Team</BreadcrumbsItem>
      <BaseTable
        columns={columns}
        data={users}
        isLoading={usersLoading}
        editedRow={editedRow}
        setEditedRow={setEditedRow}
        editRowId={editRowId}
        action={
          <PrimaryButton
            startIcon={<PersonAdd />}
            disabled={currentOrganization.status !== "active"}
            onClick={() =>
              history.push(`/organizations/${organizationName}/team/create`)
            }
          >
            Add user
          </PrimaryButton>
        }
      />
      <DeleteDialog
        onDelete={async () => {
          await deleteOrganizationUser(user.id);

          setIsDeleteDialogOpen(false);
          setRowToDelete(null);
        }}
        onClose={() => {
          setIsDeleteDialogOpen(false);
          setRowToDelete(null);
        }}
        open={isDeleteDialogOpen}
      >
        Are you sure you want to delete device <b>{rowToDelete?.email}</b>?
      </DeleteDialog>
    </PageContainer>
  );
};

export default OrganizationUsersOverview;
