import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import { Box, Grid } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { IlluSubscriptionLimit } from "assets/images/IlluSubscriptionLimit";
import { borderRadius, borders } from "assets/styles/theme/borders";
import { spacing } from "assets/styles/theme/spacing";
import { PageTabs } from "components/atoms/Tabs/PageTabs";
import { DetailsContainer } from "components/molecules/PageLayout";
import { useDeploymentCreate } from "libs/data/customized/deployment/useDeploymentCreate";
import {
  useDeploymentsGet,
  useTemplateDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { routes } from "routes";

import { PrimaryButton } from "components/atoms";

import { TRAINING_DEPLOYMENT } from "./constants";

import type { DeploymentCreate } from "libs/data/models";
import type { ReactNode } from "react";

type TrainingsOverviewProps = {
  children: ReactNode;
};

export const TrainingsOverview = ({ children }: TrainingsOverviewProps) => {
  useGoogleAnalytics();
  const { projectName, organizationName } =
    useParams<{ organizationName: string; projectName: string }>();
  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName).training,
    [organizationName, projectName]
  );
  const {
    data: deployment,
    error,
    mutate,
  } = useDeploymentsGet(projectName, TRAINING_DEPLOYMENT);

  const { data: templateDeployments } = useTemplateDeploymentsList();
  const deploymentsCreate = useDeploymentCreate(
    projectName,
    "Training successfully enabled"
  );

  const tabs = [
    {
      link: baseUrl.experiments.index(),
      label: "Experiments",
    },
    {
      link: baseUrl.evaluation.index(),
      label: "Evaluation",
    },
  ];

  const handleEnableTraining = () => {
    const trainingDeployment = templateDeployments?.find(
      (deployment) => deployment.details?.name === TRAINING_DEPLOYMENT
    )?.details;
    try {
      deploymentsCreate(trainingDeployment as DeploymentCreate).then(mutate);
    } catch (err) {
      //throw err
    }
  };

  return (
    <DetailsContainer title="Training">
      {!deployment && error ? (
        <Grid
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={2}
          minHeight="60vh"
          border={borders.primary}
          borderRadius={borderRadius[4]}
          rowGap={spacing[32]}
        >
          <IlluSubscriptionLimit />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            {explanations.training.enableTraining()}
          </Box>
          <PrimaryButton
            startIcon={<ModelTrainingIcon />}
            onClick={handleEnableTraining}
            id={"training-not-enabled"}
          >
            Enable training
          </PrimaryButton>
        </Grid>
      ) : (
        <PageTabs tabs={tabs}>{children}</PageTabs>
      )}
    </DetailsContainer>
  );
};
