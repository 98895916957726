import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import HelpIcon from "@mui/icons-material/Help";
import ArrowDown from "@mui/icons-material/KeyboardArrowDownRounded";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
import DocumentationIcon from "@mui/icons-material/MenuBookRounded";
import Menu from "@mui/icons-material/MenuRounded";
import User from "@mui/icons-material/PersonRounded";
import {
  AppBar,
  Grid,
  IconButton,
  Hidden,
  Tooltip,
  useTheme,
  Link as MuiLink,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as BrandLogoHolidaysHorizontal } from "assets/images/logo-holidays-horizontal.svg";
import { ReactComponent as BrandLogoHorizontal } from "assets/images/logo-horizontal.svg";
import { ReactComponent as BrandLogoVertical } from "assets/images/logo-vertical-white.svg";
import { DOC_LINKS, HELP_LINKS } from "libs/constants/documentation-links";
import { useUserGet } from "libs/data/endpoints/user/user";
import { getTzAwareDate } from "libs/utilities/date-util";
import { useGetCurrentOrganization } from "store/features";
import { toggleThemeMode, useGetThemeMode } from "store/features/themeMode";

import DropdownMenu from "./DropdownMenu";

import "./Header.scss";
import type { AppThemeProps } from "assets/styles/theme/theme";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.modal + 1,
    height: "44px",
    padding: "0 1em",
  },
}));

type HeaderProps = {
  toggleSidebar: () => void;
};
const Header = ({ toggleSidebar }: HeaderProps) => {
  const { data: user } = useUserGet();
  const classes = useStyles();
  const theme = useTheme() as AppThemeProps;
  const themeMode = useGetThemeMode();
  const dispatch = useDispatch();
  const currentOrgName = useGetCurrentOrganization()?.name;

  const userDropdown = useMemo(
    () => [
      {
        label: user?.email ?? "",
        divider: true,
      },
      {
        label: "Profile",
        path: "/profile",
      },
      {
        label: "Sign out",
        path: "/sign-out",
      },
    ],
    [user]
  );

  const handleThemModeClick = () => {
    themeMode.mode == "dark"
      ? dispatch(toggleThemeMode("light"))
      : dispatch(toggleThemeMode("dark"));
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      classes={{ root: classes.appBar }}
      color="primary"
      sx={{
        backgroundColor: theme.palette.header.background,
        color: theme.palette.header.color,
      }}
    >
      <Grid
        container
        alignItems="center"
        spacing={1}
        classes={{ root: "header__container" }}
        wrap="nowrap"
      >
        <Hidden mdUp implementation="js">
          <Grid item>
            <Tooltip title="Menu" arrow>
              <IconButton
                onClick={toggleSidebar}
                edge="start"
                className="header__icon-button"
                sx={{
                  "&:hover": { backgroundColor: theme.palette.primary.light },
                }}
              >
                <Menu fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Hidden>
        <Grid
          item
          component={Link}
          to={`/organizations/${currentOrgName}/dashboard`}
          classes={{ root: "header__home-link" }}
        >
          <Hidden only="xs">
            {
              // If it's december use the holiday logo
              getTzAwareDate().format("M") === "12" ? (
                <BrandLogoHolidaysHorizontal className="header__logo header__logo--holidays-horizontal" />
              ) : (
                <BrandLogoHorizontal className="header__logo header__logo--horizontal" />
              )
            }
          </Hidden>
          <Hidden smUp>
            <BrandLogoVertical className="header__logo header__logo--vertical" />
          </Hidden>
        </Grid>
        <Grid item xs />

        <Hidden xsDown>
          <Hidden>
            <IconButton
              onClick={handleThemModeClick}
              className="header__icon-button"
              sx={{
                "&:hover": { backgroundColor: theme.palette.primary.light },
              }}
            >
              {themeMode.mode === "dark" ? (
                <LightModeOutlined
                  sx={{
                    color: theme.palette.neutrals[900],
                  }}
                />
              ) : (
                <DarkModeOutlined
                  sx={{
                    color: theme.palette.neutrals[0],
                  }}
                />
              )}
            </IconButton>
          </Hidden>
          <DropdownMenu
            icon={
              <>
                <HelpIcon fontSize="small" />
                <ArrowDown fontSize="small" />
              </>
            }
            items={HELP_LINKS}
            className="header__icon-button"
            sx={{ "&:hover": { backgroundColor: theme.palette.primary.light } }}
          />
        </Hidden>

        <Hidden xsDown>
          <Tooltip title="Documentation">
            <MuiLink
              href={DOC_LINKS.DOCS}
              target="_blank"
              className="header__icon-button"
              sx={{
                "&:hover": { backgroundColor: theme.palette.primary.light },
              }}
            >
              <DocumentationIcon
                fontSize="small"
                style={{ marginTop: "4px" }}
              />
            </MuiLink>
          </Tooltip>
        </Hidden>
        <Grid item style={{ paddingLeft: 0 }}>
          <DropdownMenu
            icon={<User fontSize="small" />}
            items={userDropdown}
            className="header__icon-button"
            sx={{ "&:hover": { backgroundColor: theme.palette.primary.light } }}
          />
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
