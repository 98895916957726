import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import PersonAdd from "@mui/icons-material/PersonAddRounded";
import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useHistory, useParams } from "react-router-dom";

import { FIELD_NAME } from "libs/constants/fields";
import { NOTIFICATION_GROUPS_PERMISSIONS } from "libs/constants/permissions";
import { useNotificationGroupDelete } from "libs/data/customized/monitoring/useNotificationGroupDelete";
import { useNotificationGroupUpdate } from "libs/data/customized/monitoring/useNotificationGroupUpdate";
import { useOrganizationUser } from "libs/data/customized/user";
import { useNotificationGroupsList } from "libs/data/endpoints/monitoring/monitoring";
import { useGoogleAnalytics, useNotificationGroups } from "libs/hooks";
import { useGetPermissions } from "store/features/permissions";

import {
  DeleteDialog,
  IconButton,
  Loader,
  PrimaryButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";
import { NotificationGroupEditCreate } from "components/organisms";

import NotificationGroupDetails from "./NotificationGroupDetails";

import type { BaseColumn, BaseRow } from "components/molecules/BaseTable";
import type {
  NotificationGroupContact,
  NotificationGroupList,
  NotificationGroupUpdate,
} from "libs/data/models";

const MonitoringNotificationGroups = () => {
  useGoogleAnalytics();
  const match = useRouteMatch();
  const history = useHistory();
  const [rowToDelete, setRowToDelete] = useState("");
  const [editRowId, setEditRowId] = useState<string | undefined>(undefined);
  const [editedRow, setEditedRow] = useState<BaseRow | undefined>(undefined);
  const [expandedId, setExpandedId] = useState<string | null>(null);

  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { data, error, isAdmin } = useOrganizationUser(organizationName);
  const isLoadingUser = !error && !data;

  const [currentPermissions] = useGetPermissions();
  const { data: notificationGroups, error: listError } =
    useNotificationGroupsList(projectName);

  const deleteNotificationGroup = useNotificationGroupDelete(projectName);
  const updateNotificationGroup = useNotificationGroupUpdate(projectName);

  const isLoading = !notificationGroups && !listError;
  useEffect(() => {
    if (
      // isAdmin is false by default before the user is loaded so we need to check if the API call finished
      (data && !isAdmin) ||
      // This makes sure the permissions are actually loaded and are not undefined
      (currentPermissions[NOTIFICATION_GROUPS_PERMISSIONS.list] === false &&
        currentPermissions[NOTIFICATION_GROUPS_PERMISSIONS.get] === false)
    ) {
      history.push(
        `/organizations/${organizationName}/projects/${projectName}/monitoring`
      );
    }
  }, [
    currentPermissions,
    isAdmin,
    organizationName,
    projectName,
    history,
    data,
    error,
  ]);

  const { dialogProps: createDialogProps, toggleDialog: createDialogToggle } =
    useNotificationGroups({
      isUpdate: false,
      selectOrCreate: false,
    });
  const { dialogProps: updateDialogProps, toggleDialog: updateDialogToggle } =
    useNotificationGroups({
      isUpdate: true,
      selectOrCreate: false,
    });

  const onUpdateNotificationGroupName = useCallback(
    async (
      oldNotificationGroup: NotificationGroupList,
      newNotificationGroup: NotificationGroupList
    ) => {
      const updatedNotificationGroup: NotificationGroupUpdate = {
        name: oldNotificationGroup.name,
      };
      if (oldNotificationGroup.name !== newNotificationGroup.name) {
        updatedNotificationGroup[FIELD_NAME] = newNotificationGroup.name;
      }

      try {
        await updateNotificationGroup(
          oldNotificationGroup.name,
          updatedNotificationGroup
        );
      } catch (err) {
        // throw err
      }
    },
    [updateNotificationGroup]
  );

  const [currentNotificationGroup, setCurrentNotificationGroup] =
    useState<undefined | NotificationGroupList>(undefined);

  const openUpdatedialog = useCallback(
    (notificationGroup) => {
      setCurrentNotificationGroup(notificationGroup);
      updateDialogToggle();
    },
    [setCurrentNotificationGroup, updateDialogToggle]
  );

  const columns = useMemo(
    () => [
      {
        title: "",
        field: "expand",
        width: "1%",
      },
      {
        title: "Group",
        field: "name",
        editable: true,
        width: "20%",
      },
      {
        disableClick: true,
        align: "right",
        render: (rowData: NotificationGroupList) => (
          <Box display="flex" justifyContent="flex-end">
            {editRowId === rowData?.id ? (
              <>
                <IconButton
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await onUpdateNotificationGroupName(
                      rowData,
                      editedRow as NotificationGroupList
                    );
                    setEditedRow(undefined);
                    setEditRowId(undefined);
                  }}
                  icon={CheckIcon}
                />
                <IconButton
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditRowId(undefined);
                    setEditedRow(undefined);
                  }}
                  icon={CloseIcon}
                />
              </>
            ) : (
              <>
                <IconButton
                  tooltip="Add members"
                  htmlColor="secondary"
                  hoverColor="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    openUpdatedialog(rowData);
                  }}
                  icon={PersonAdd}
                />
                <IconButton
                  icon={Edit}
                  htmlColor="secondary"
                  hoverColor="secondary"
                  tooltip="Edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpandedId(null);
                    setEditRowId(rowData.id);
                    setEditedRow(rowData);
                  }}
                />
                <IconButton
                  icon={Delete}
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRowToDelete(rowData.name);
                  }}
                />
              </>
            )}
          </Box>
        ),
      },
    ],
    [editRowId, editedRow, onUpdateNotificationGroupName, openUpdatedialog]
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Notification Groups</BreadcrumbsItem>
      {isLoading || isLoadingUser ? (
        <Loader />
      ) : (
        <>
          <BaseTable
            columns={columns as unknown as BaseColumn[]}
            data={notificationGroups}
            onRowClick={(_e, rowData) => {
              if (rowData?.id !== expandedId) {
                setExpandedId(rowData?.id);
              } else {
                setExpandedId(null);
              }
            }}
            renderDetailPanel={(rowData: BaseRow) => {
              return (
                <NotificationGroupDetails
                  notificationGroup={convertRowToTableData(rowData.contacts)}
                />
              );
            }}
            expandedRowIndex={notificationGroups?.findIndex(
              (group) => group.id === expandedId
            )}
            clearExpandedRow={() => setExpandedId(null)}
            editRowId={editRowId}
            editedRow={editedRow}
            setEditedRow={setEditedRow}
            action={
              <PrimaryButton
                startIcon={<GroupIcon />}
                onClick={createDialogToggle}
              >
                Create notification group
              </PrimaryButton>
            }
          />
          <NotificationGroupEditCreate
            {...createDialogProps}
            selectedNotificationGroup={currentNotificationGroup}
          />
          <NotificationGroupEditCreate
            {...updateDialogProps}
            selectedNotificationGroup={currentNotificationGroup}
          />
        </>
      )}
      <DeleteDialog
        onDelete={async () => {
          await deleteNotificationGroup(rowToDelete);
          setRowToDelete("");
        }}
        onClose={() => setRowToDelete("")}
        open={!!rowToDelete}
      >
        Are you sure you want to remove this notification group from the
        project?
      </DeleteDialog>
    </>
  );
};

const convertRowToTableData = (data: NotificationGroupContact[]) =>
  data.map((contact) => ({
    type: contact.type,
    email: contact.configuration.email_address ?? "",
  }));

export default MonitoringNotificationGroups;
