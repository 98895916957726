import * as Yup from "yup";

import {
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_NAME,
  FIELD_PROVIDER,
} from "libs/constants/fields";

import type { LabelsDict } from "components/molecules";

import type {
  FIELD_TTL,
  FIELD_CREDENTIALS,
  FIELD_CONFIGURATION,
} from "libs/constants/fields";
import type {
  BucketCreateCredentials,
  BucketDetailConfiguration,
} from "libs/data/models";

export const PROVIDER = {
  ubiops: "ubiops",
  externalCloud: "external",
};

export const BUCKET_OPTIONS = [
  {
    label: "Create new UbiOps bucket",
    value: PROVIDER.ubiops,
  },
  {
    label: "Connect to existing cloud bucket",
    value: PROVIDER.externalCloud,
  },
];

export type VerifySSLOption = { value: null | boolean };
export interface BucketBaseProps {
  [FIELD_NAME]: string;
  [FIELD_DESCRIPTION]: string;
  [FIELD_CONFIGURATION]?: BucketDetailConfiguration | null;
  [FIELD_CREDENTIALS]?: BucketCreateCredentials | null;
  [FIELD_LABELS]?: LabelsDict;
  [FIELD_PROVIDER]: string;
  [FIELD_TTL]?: number | null;
}

export const BucketBaseValues: BucketBaseProps = {
  [FIELD_NAME]: "",
  [FIELD_DESCRIPTION]: "",
  [FIELD_LABELS]: {},
  [FIELD_PROVIDER]: PROVIDER.ubiops,
};

export const validationSchema = Yup.object().shape({
  [FIELD_NAME]: Yup.string().required(),
});
