import { BaseButtonWithTooltip } from "components/atoms/Button/BaseButtonWithTooltip";
import { useDeviceDetect } from "libs/hooks";

import type { ButtonProps } from "components/atoms/Button/types";
import "./TextButton.scss";

export interface TextButtonProps extends ButtonProps {
  color?: "primary" | "secondary";
}

export const TextButton = ({
  color = "primary",
  style = {},
  children,
  ...props
}: TextButtonProps) => {
  const { isMobile } = useDeviceDetect();

  return (
    <BaseButtonWithTooltip
      className="TextButton"
      color={color}
      style={{
        textTransform: "uppercase",
        padding: "6px 8px",
        ...style,
        minWidth: isMobile ? 0 : "unset",
      }}
      {...props}
    >
      {isMobile ? "" : children}
    </BaseButtonWithTooltip>
  );
};
