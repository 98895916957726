import Edit from "@mui/icons-material/EditRounded";
import { Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelect } from "components/atoms/AutoCompleteSelect";
import { useDeploymentVersionRequestsBatchGet } from "libs/data/customized/deployment-requests/useDeploymentVersionRequestsBatchGet";
import { useDeploymentVersionRequestsList } from "libs/data/endpoints/deployment-requests/deployment-requests";
import { useDeploymentVersionsList } from "libs/data/endpoints/deployments/deployments";
import { DeploymentRequestBatchDetailStatus } from "libs/data/models";
import { getWrappedDeploymentVersionRequestsBatch } from "libs/data/wrappers/getWrappedDeploymentVersionRequestsBatch";
import { ENV_NAMES, env } from "libs/env";
import { ColumnSelectionDialog } from "pages/organizations/:organizationName/projects/:projectName/training/evaluation/ColumnSelectionDialog";

import { LabelChip, Loader, SecondaryButton } from "components/atoms";

import { ComparisonTable } from "./ComparisonTable";
import { EmptyTable } from "./EmptyTable";
import { createUrl } from "../../logs/utils";
import { TRAINING_DEPLOYMENT } from "../constants";
import { ExperimentEmptyOverview } from "../experiments/ExperimentEmptyOverview";
import {
  getTrainingRunsFromUrl,
  transformTrainingRunsToSearchParams,
} from "../experiments/util";

import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { DeploymentRequestBatchDetail } from "libs/data/models";
import type { ProjectDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/types";

export const Evaluation = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { projectName } = useParams<ProjectDetailsRouteParams>();
  const location = useLocation();
  const itemsInUrl = getTrainingRunsFromUrl(location.search) ?? [];
  const savedColumns = env.get(ENV_NAMES.EVALUATION_TABLE_COLUMNS);

  const [selectedExperiment, setSelectedExperiment] =
    useState<AutocompleteSelectOption | null>(null);
  const [runsToCompare, setRunsToCompare] = useState<
    DeploymentRequestBatchDetail[]
  >([]);
  const [columns, setColumns] = useState<string[]>(
    savedColumns ? JSON.parse(savedColumns) : []
  );
  const [isColumnDialogOpen, setIsColumnDialogOpen] = useState<boolean>(false);

  const { data: experiments } = useDeploymentVersionsList(
    projectName,
    TRAINING_DEPLOYMENT
  );

  const { data: trainingRuns } = useDeploymentVersionRequestsList(
    projectName,
    TRAINING_DEPLOYMENT,
    selectedExperiment?.value as string,
    {},
    { swr: { enabled: !!selectedExperiment } }
  );

  const { data: trainingRunDetails } = useDeploymentVersionRequestsBatchGet(
    projectName,
    TRAINING_DEPLOYMENT,
    selectedExperiment?.value as string,
    trainingRuns?.map((item) => item.id),
    { swr: { enabled: !!selectedExperiment && !!trainingRuns?.length } }
  );

  const [loadingUrlRuns, setLoadingUrlRuns] = useState(true);

  useEffect(() => {
    if (itemsInUrl?.length) {
      getWrappedDeploymentVersionRequestsBatch(
        projectName,
        TRAINING_DEPLOYMENT,
        itemsInUrl
      )
        .then((results) => {
          if (results?.length) {
            const metrics = results
              .map((result) => Object.keys(result?.result?.metrics ?? {}))
              .flat();
            if (!columnsContainMetrics(metrics))
              setColumns([...new Set(metrics)]);
          }
          setRunsToCompare(results);
        })
        .finally(() => {
          setLoadingUrlRuns(false);
        });
    } else {
      setLoadingUrlRuns(false);
    }
    // This effect should only run once on start-up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsContainMetrics = (metrics: string[]) => {
    const newColumns = columns
      .map((column) => {
        return metrics.find((metric) => metric === column);
      })
      .filter(Boolean);

    return newColumns.length;
  };

  const experimentOptions = useMemo((): AutocompleteSelectOption[] => {
    return (
      experiments?.map(({ version }) => ({
        label: version,
        value: version,
      })) ?? []
    );
  }, [experiments]);

  const selectedRunIds: string[] = useMemo(
    () => runsToCompare.map((run) => run.id),
    [runsToCompare]
  );

  const trainingRunOptions = useMemo((): AutocompleteSelectOption[] => {
    return trainingRuns?.length && trainingRunDetails?.length
      ? trainingRunDetails
          .filter((detail) => !selectedRunIds.includes(detail.id))
          .filter(
            (detail) =>
              detail.status === DeploymentRequestBatchDetailStatus.completed
          )
          .map((detail) => ({
            label: detail.request_data?.name || "Name not available",
            value: detail.id as string,
          }))
      : [];
  }, [trainingRuns?.length, trainingRunDetails, selectedRunIds]);

  const availableColumns = useMemo(() => {
    const metrics =
      runsToCompare?.map((run) => Object.keys(run?.result?.metrics ?? {})) ??
      [];

    return [...new Set(metrics.flat())];
  }, [runsToCompare]);

  const addRunToEvaluation = (runId: string) => {
    const details = trainingRunDetails?.find((detail) => detail.id === runId);

    if (details) {
      if (!columns.length) {
        const metrics = details?.result?.metrics ?? {};
        const keys = Object.keys(metrics);
        setColumns(keys);
      }

      setRunsToCompare((runs: any) => [...runs, details]);
    }
  };

  const removeRunFromEvaluation = (runId: string) => {
    setRunsToCompare((runs) => runs.filter((run) => run.id !== runId));
  };

  const runLabels: { label: string; value: string }[] = useMemo(() => {
    return runsToCompare.map((run: DeploymentRequestBatchDetail) => {
      const experiment = run.version;
      const name = run.request_data?.name || "Name not available";

      return {
        label: `${experiment}: ${name}`,
        value: run.id as string,
      };
    });
  }, [runsToCompare]);

  useEffect(() => {
    if (
      runsToCompare?.length !== (itemsInUrl?.length || 0) &&
      !loadingUrlRuns
    ) {
      const newUrl = createUrl(location.pathname, {
        ids: transformTrainingRunsToSearchParams(runsToCompare),
      });

      history.replace(newUrl);
    } else if (runsToCompare?.length === 0 && itemsInUrl?.length === 0) {
      // This prevents unnecessary "?ids=" to be added to the url
      history.replace(location.pathname);
    }
  }, [
    itemsInUrl?.length,
    history,
    location.pathname,
    runsToCompare,
    loadingUrlRuns,
  ]);

  if (loadingUrlRuns) return <Loader />;
  if (!loadingUrlRuns && experimentOptions?.length === 0)
    return (
      <Grid xs={12} padding={2}>
        <ExperimentEmptyOverview />
      </Grid>
    );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Evaluation</BreadcrumbsItem>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} component={Typography} variant="h5" gutterBottom>
          Add runs to compare
        </Grid>
        <Grid item xs={5}>
          <AutoCompleteSelect
            onChange={(option) => setSelectedExperiment(option)}
            value={selectedExperiment ?? { label: "", value: "" }}
            options={experimentOptions}
            label="Experiment"
          />
        </Grid>
        <Grid item xs={5}>
          {selectedExperiment && (
            <AutoCompleteSelect
              label="Training run"
              onChange={(value) =>
                value && addRunToEvaluation(value.value as string)
              }
              options={trainingRunOptions}
              placeholder="Select more"
            />
          )}
        </Grid>
        <Grid item xs={12} style={{ margin: `${spacing[20]} 0` }}>
          {runLabels?.length > 0 && (
            <Grid container>
              {runLabels?.map(({ label, value }) => (
                <Grid
                  item
                  key={value}
                  style={{ marginRight: spacing[16], marginBottom: spacing[6] }}
                >
                  <LabelChip
                    label={label}
                    onDelete={() => removeRunFromEvaluation(value)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        {runsToCompare?.length > 0 ? (
          <>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: spacing[8],
                marginTop: -spacing[8],
              }}
            >
              <SecondaryButton
                disabled={!availableColumns?.length}
                onClick={() => setIsColumnDialogOpen(true)}
                startIcon={<Edit />}
              >
                Edit columns
              </SecondaryButton>
            </Grid>
            <Grid item xs={12}>
              <ComparisonTable
                data={runsToCompare}
                columns={columns.filter((column) =>
                  availableColumns.includes(column)
                )}
              />
            </Grid>
          </>
        ) : (
          <EmptyTable text="Add training runs to compare" />
        )}
      </Grid>
      <ColumnSelectionDialog
        availableColumns={availableColumns}
        onChange={setColumns}
        onClose={() => setIsColumnDialogOpen(false)}
        open={isColumnDialogOpen}
        selectedColumns={columns}
      />
    </>
  );
};
