import Copy from "@mui/icons-material/FileCopyRounded";
import copy from "copy-to-clipboard";
import { useState, useEffect } from "react";

import { IconButton } from "./IconButton";

import type { IconButtonProps } from "./IconButton";

type CopyToClipboardButtonProps = {
  defaultLabel?: string;
  contentToCopy: string;
  hoverColor?: string;
} & Omit<IconButtonProps, "icon">;

export const CopyToClipboardButton = ({
  defaultLabel = "Copy to clipboard",
  contentToCopy,
  ...props
}: CopyToClipboardButtonProps) => {
  const [label, setLabel] = useState(defaultLabel);
  const [timeoutObject, setTimeoutObject] =
    useState<NodeJS.Timeout | null>(null);

  const copyLogToClipboard = () => {
    copy(contentToCopy);
    setLabel("Copied!");
    setTimeoutObject(
      setTimeout(() => {
        setLabel(defaultLabel);
      }, 5000)
    );
  };

  useEffect(() => {
    return () => {
      timeoutObject && clearTimeout(timeoutObject);
    };
  }, [timeoutObject]);

  return (
    <IconButton
      icon={Copy}
      htmlColor="secondary"
      hoverColor="secondary"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        copyLogToClipboard();
      }}
      fontSize="small"
      tooltip={label}
      id="copy-to-clipboard"
      {...props}
    />
  );
};
