import { Box, Grid, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";

import { PageTitle } from "components/atoms/Typography/PageTitle";
import { PageBreadcrumbs } from "components/molecules/PageLayout/Header/PageBreadcrumbs";
import { useDeviceDetect } from "libs/hooks";

import { Divider } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { ReactNode } from "react";

export interface HeaderProps {
  actions?: ReactNode;
  title: string | ReactNode;
  children?: ReactNode;
  pageTitle?: ReactNode;
}

export const PageHeader = ({
  actions,
  title,
  pageTitle,
  children,
}: HeaderProps) => {
  const theme = useTheme() as AppThemeProps;
  const { isMobile } = useDeviceDetect();

  return (
    <Grid xs={12}>
      <div className="page-title">
        <Helmet>
          <title>UbiOps | {title}</title>
        </Helmet>
        <PageBreadcrumbs />
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box mr={3} p={1} flexShrink={1}>
            <PageTitle>{pageTitle ?? title}</PageTitle>
          </Box>
          {children}
          {!!actions && (
            <Box
              p={1}
              flexShrink={0}
              flexGrow={1}
              display="flex"
              flexWrap="wrap"
              sx={{
                ".ButtonGroup > :not(:last-child)": {
                  marginRight: isMobile ? 0 : "8px",
                },
              }}
            >
              {actions}
            </Box>
          )}
        </Box>
        <Divider mt={1} mb={3} color={theme.palette.border.divider} />
      </div>
    </Grid>
  );
};
