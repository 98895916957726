import Plus from "@mui/icons-material/AddBoxRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import { Typography, Box } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { BaseUrlContext } from "libs/contexts";
import { useDeviceDelete, useDeviceUpdate } from "libs/data/customized/devices";
import { useDevicesList, useUserGet } from "libs/data/endpoints/user/user";
import { formatLabel, getRandomId } from "libs/utilities/utils";

import {
  StatusIcon,
  Loader,
  InfoAlert,
  PrimaryButton,
  DeleteDialog,
  IconButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";

const DevicesOverview = () => {
  const [loading, setLoading] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [editRowId, setEditRowId] = useState(undefined);
  const [editedRow, setEditedRow] = useState(undefined);

  const baseUrl = useContext(BaseUrlContext);
  const history = useHistory();
  const { data: user } = useUserGet();
  const { data: devices, error } = useDevicesList();
  const isLoadingDevices = !devices && !error;

  const deleteDevice = useDeviceDelete(true);
  const updateDevice = useDeviceUpdate();

  const onRowUpdate = async (oldDevice, newDevice) => {
    if (oldDevice.name !== newDevice.name) {
      setLoading(true);
      await updateDevice(oldDevice.name, { name: newDevice.name });
      setLoading(false);
      setEditRowId(undefined);
    }
  };

  const onRowDelete = async (item) => {
    setLoading(true);
    await deleteDevice(item.name);
    setLoading(false);
  };

  const devicesWithId = useMemo(
    () => devices?.map((device) => ({ ...device, id: getRandomId() })),
    [devices]
  );

  const columns = [
    {
      title: "Status",
      field: "confirmed",
      width: "10%",
      render: (rowData) => (
        <>
          <StatusIcon
            label={!rowData?.confirmed ? "not confirmed" : "confirmed"}
            status={!rowData?.confirmed ? "failed" : "available"}
          />
        </>
      ),
      size: "small",
    },
    {
      title: "Name",
      field: "name",
      editable: "true",
      width: "10%",
      defaultSort: "asc",
    },
    {
      disableClick: true,
      width: "15%",
      align: "right",

      render: (rowData) => (
        <Box display="flex" justifyContent="flex-end">
          {editRowId === rowData?.id ? (
            <>
              <IconButton
                htmlColor="primary"
                hoverColor="primary"
                onClick={() => {
                  onRowUpdate(rowData, editedRow);
                }}
                icon={CheckIcon}
              />
              <IconButton
                htmlColor="primary"
                hoverColor="primary"
                onClick={() => setEditRowId(undefined)}
                icon={CloseIcon}
              />
            </>
          ) : (
            <>
              {!rowData?.confirmed && (
                <IconButton
                  tooltip="Confirm device"
                  htmlColor="primary"
                  hoverColor="primary"
                  onClick={() => history.push("2fa/create", rowData.name)}
                  icon={LinkIcon}
                />
              )}
              <IconButton
                icon={Edit}
                htmlColor="secondary"
                hoverColor="secondary"
                tooltip="Edit"
                onClick={() => setEditRowId(rowData.id)}
              />
              <IconButton
                icon={Delete}
                tooltip="Remove device"
                htmlColor="primary"
                hoverColor="primary"
                onClick={() => {
                  setIsDeleteDialogOpen(true);
                  setRowToDelete(rowData);
                }}
              />
            </>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box px={3}>
      <Typography variant="h3" marginBottom={spacing[12]}>
        Two-Factor authentication
      </Typography>
      {user?.authentication === "ubiops" ? (
        isLoadingDevices ? (
          <Loader />
        ) : (
          <BaseTable
            isLoading={loading}
            columns={columns}
            data={devicesWithId}
            editedRow={editedRow}
            setEditedRow={setEditedRow}
            editRowId={editRowId}
            action={
              <PrimaryButton
                onClick={() => history.push(`${baseUrl}/2fa/create`)}
                startIcon={<Plus />}
              >
                Add device
              </PrimaryButton>
            }
            hasSearchField={false}
            hasPagination={false}
          />
        )
      ) : (
        <InfoAlert>
          Your account is managed by {formatLabel(user?.authentication)}. Go to
          your {formatLabel(user?.authentication)} account settings to change
          your settings.
        </InfoAlert>
      )}

      <DeleteDialog
        onDelete={async () => {
          await onRowDelete(rowToDelete);
          setIsDeleteDialogOpen(false);
          setRowToDelete(null);
        }}
        onClose={() => {
          setIsDeleteDialogOpen(false);
          setRowToDelete(null);
        }}
        open={isDeleteDialogOpen}
      >
        Are you sure you want to delete device <b>{rowToDelete?.name}</b>?
      </DeleteDialog>
    </Box>
  );
};

export default DevicesOverview;
