import { Box, Grid, useTheme } from "@mui/material";
import { CodeBlock } from "react-code-blocks";
import { useParams } from "react-router-dom";

import { EnvironmentRequirementsFileIllustration as FileIcon } from "assets/images/EnvironmentRequirementsFile";
import {
  useEnvironmentBuildDependenciesList,
  useEnvironmentsGet,
} from "libs/data/endpoints/environments/environments";

import { InfoAlert, SubHeader } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { EnvironmentBuildDependency } from "libs/data/models";
import type { EnvironmentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/environments/custom/:environmentName/types";

export const EnvironmentDependencies = () => {
  const { projectName, environmentName } =
    useParams<EnvironmentDetailsRouteParams>();
  const { data: environmentDetails } = useEnvironmentsGet(
    projectName,
    environmentName
  );

  const { data: dependencies } = useEnvironmentBuildDependenciesList(
    projectName,
    environmentName,
    environmentDetails?.active_revision as string,
    environmentDetails?.active_build as string,
    {
      swr: {
        enabled:
          !!environmentDetails?.active_build &&
          !!environmentDetails?.active_revision,
      },
    }
  );

  const theme = useTheme() as AppThemeProps;

  return (
    <Grid container spacing={2}>
      {!!dependencies?.length &&
        dependencies.map((dependency: EnvironmentBuildDependency) => (
          <Grid key={dependency.name} item xs={12} md={6}>
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                marginBottom: 6,
              }}
            >
              <FileIcon />
              <SubHeader marginLeft={"6px"}>{dependency.name}</SubHeader>
            </Box>
            {dependency.content !== null && (
              <Box
                style={{
                  border: `1px solid ${theme.palette.background.codeBlock}`,
                  borderRadius: 8,
                  fontFamily: "Courier",
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "16px",
                  overflow: "hidden",
                }}
              >
                <CodeBlock
                  customStyle={{
                    backgroundColor: theme.palette.background.codeBlock,
                    color: theme.palette.text.primary,
                    padding: "6px 12px",
                  }}
                  // todo: remove this once this has been addressed:
                  // https://github.com/rajinwonderland/react-code-blocks/issues/95
                  // @ts-ignore
                  text={dependency.content}
                  theme={{}}
                />
              </Box>
            )}
            {dependency.content === null && (
              <InfoAlert>
                Your {dependency.name} file could not be read or is larger than
                8k. Download your latest revision to inspect your requirements.
              </InfoAlert>
            )}
          </Grid>
        ))}
    </Grid>
  );
};
