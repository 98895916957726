import BarChartIcon from "@mui/icons-material/BarChart";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router";

import { spacing } from "assets/styles/theme";
import { useGetSpecialColors } from "assets/styles/theme/utils/chartColors";
import { useProjectsUsageGet } from "libs/data/endpoints/projects/projects";
import { getTzAwareDate } from "libs/utilities/date-util";
import { downloadFile } from "libs/utilities/download-helper";
import {
  extractDistinctDatesFromMetrics,
  getBarChartDatasetOptions,
  getBarChartOptions,
  mapValuesToDates,
} from "libs/utilities/metrics-helper";
import { getRandomId } from "libs/utilities/utils";

import { CardHeaderTitle } from "components/atoms";
import { MetricGraph } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type {
  ProjectDeploymentVersionUsage,
  TimeSeriesDataPointList,
} from "libs/data/models";

const dates = {
  startDate: getTzAwareDate().subtract(2, "week"),
  endDate: getTzAwareDate().add(1, "day"),
};

const getTopTenChartId = (
  dataArray: ProjectDeploymentVersionUsage[] | undefined
) => {
  if (!dataArray?.length) return [];

  const calculatedSums = dataArray.map((item) => {
    const value = item.data.reduce(
      (sum, val) => (sum || 0) + (val.value || 0),
      0
    );

    return { id: item.deployment_id, value: value };
  });

  calculatedSums.sort((a, b) => (b.value || 0) - (a.value || 0));
  const top10 = calculatedSums.slice(0, 10).map((item) => item.id);

  return top10;
};

export const DeploymentsUsageCard = () => {
  const { projectName } = useParams<{ projectName: string }>();
  const theme = useTheme() as AppThemeProps;
  const { data } = useProjectsUsageGet(projectName, {
    start_date: dates.startDate.startOf("day").toISOString(),
    end_date: dates.endDate.endOf("day").toISOString(),
    interval: "day",
  });
  const chartColors = useGetSpecialColors();

  const usageGraph = useMemo(() => {
    if (data) {
      const deploymentsUsage = data.data_deleted_deployments.length
        ? [
            ...(data?.data_deployments || []),
            {
              deployment_name: "deleted deployments",
              data: data?.data_deleted_deployments,
              deployment_id: getRandomId(),
              version: "",
              version_id: "",
            } as ProjectDeploymentVersionUsage,
          ]
        : data.data_deployments;

      const datasets = deploymentsUsage ?? [];
      const topTenIds = getTopTenChartId(datasets);
      const otherDatasets = datasets?.filter(
        (dataset) => !topTenIds?.includes(dataset.deployment_id)
      );
      const otherData: TimeSeriesDataPointList[] = datasets[0]?.data?.map?.(
        (data, i) => ({
          ...data,
          value: otherDatasets?.reduce(
            (acc, curr) => curr.data?.[i]?.value ?? 0 + acc,
            0
          ),
        })
      );

      const reducedDatasets =
        topTenIds?.length < 10
          ? datasets
          : [
              ...datasets.filter((data) =>
                topTenIds.includes(data.deployment_id)
              ),
              {
                deployment_id: "others",
                deployment_name: "Others",
                version: "",
                data: otherData,
              },
            ];

      const dateLabels = extractDistinctDatesFromMetrics(
        datasets.map(({ data }) => data)
      );

      return {
        data: {
          labels: dateLabels,
          tooltips: dateLabels,
          datasets: reducedDatasets?.map((usage, idx) => ({
            data: mapValuesToDates(usage.data, dateLabels),
            id: usage.deployment_name,
            ...getBarChartDatasetOptions({
              label: usage?.deployment_name,
              tooltipLabel: usage?.version
                ? `${usage?.deployment_name}(${usage.version})`
                : usage.deployment_name,
              color: chartColors[idx],
            }),
          })),
        },
        options: getBarChartOptions({
          name: "Credit usage",
          stacked: true,
          crosshair: true,
          startDate: dates.startDate.startOf("day").toISOString(),
          endDate: dates.endDate.startOf("day").toISOString(),
          timeUnit: "day",
          theme: theme,
        }),
        title: "Credit usage per deployment",
        minHeight: "100%",
        chartComponent: Bar,
      };
    }

    return null;
  }, [data, theme, chartColors]);

  return (
    <Grid>
      {usageGraph && (
        <MetricGraph
          height="400px"
          onDownloadData={() => {
            downloadFile(
              JSON.stringify(data?.data_deployments || {}, null, 2),
              "credit_usage.json"
            );
          }}
          {...usageGraph}
          customTitle={
            <CardHeaderTitle
              style={{
                display: "flex",
              }}
            >
              <BarChartIcon
                fontSize="small"
                width={20}
                height={20}
                sx={{
                  color: theme.palette.text.primary,
                  marginRight: spacing[8],
                  marginTop: "1px",
                  marginLeft: "5px",
                }}
              />
              <Typography variant="h5">Credit usage per deployment</Typography>
            </CardHeaderTitle>
          }
          fullPageWidth
        />
      )}
    </Grid>
  );
};
