import Plus from "@mui/icons-material/AddBoxRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopy from "@mui/icons-material/ContentCopy";
import TrashOff from "@mui/icons-material/DeleteForever";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Copy from "@mui/icons-material/FileCopyRounded";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Lock } from "react-feather";
import { useDispatch } from "react-redux";
import { useRouteMatch, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import {
  deploymentEnvironmentVariablesCreate,
  deploymentEnvironmentVariablesDelete,
  deploymentEnvironmentVariablesUpdate,
  deploymentVersionEnvironmentVariablesCreate,
  deploymentVersionEnvironmentVariablesDelete,
  deploymentVersionEnvironmentVariablesUpdate,
  useDeploymentEnvironmentVariablesList,
  useDeploymentVersionEnvironmentVariablesList,
} from "libs/data/endpoints/deployments/deployments";
import { secretValues } from "libs/utilities/labels-mapping";
import { createErrorNotification } from "libs/utilities/notifications";
import { getRandomId } from "libs/utilities/utils";
import validators from "libs/utilities/validators";

import {
  Icon,
  WarningText,
  DeleteDialog,
  PrimaryButton,
  SecondaryButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import "./EnvVarsOverview.scss";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { BaseColumn, BaseRow } from "components/molecules/BaseTable";
import type {
  InheritedEnvironmentVariableList,
  EnvironmentVariableCreate,
} from "libs/data/models";

const isInherited = (data: InheritedEnvironmentVariableList) =>
  !!data?.inheritance_type;

type EnvVarsOverviewProps = {
  deploymentName: string;
  warningMessage?: string;
  deploymentVersionName?: string;
  isCreatable?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  isUsedInForm?: boolean;
  isCopyable?: boolean;
  includeInheritance?: boolean;
  onCopyClick?: () => void;
  onAdd?: (envVar: InheritedEnvironmentVariableList) => void;
  onDelete?: (envVar: InheritedEnvironmentVariableList) => void;
  onChange?: (id: string, variable: InheritedEnvironmentVariableList) => void;
  envVars?: InheritedEnvironmentVariableList[];
};

export const EnvVarsOverview = ({
  deploymentName,
  warningMessage,
  deploymentVersionName,
  isCreatable = true,
  isEditable = true,
  isDeletable = true,
  isUsedInForm = false,
  isCopyable = true,
  includeInheritance = true,
  onCopyClick,
  onAdd,
  onDelete,
  onChange,
  envVars,
}: EnvVarsOverviewProps) => {
  const { projectName } = useParams<{ projectName: string }>();
  const match = useRouteMatch();
  const theme = useTheme() as AppThemeProps;
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedItemToDelete, setSelectedItemToDelete] =
    useState<InheritedEnvironmentVariableList | undefined>(undefined);
  const [editRowId, setEditRowId] = useState<string | undefined>(undefined);
  const [editedRow, setEditedRow] = useState<BaseRow | undefined>(undefined);
  const [createMode, setCreateMode] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    data: deploymentVariables,
    mutate: deploymentVariablesMutate,
    isValidating,
  } = useDeploymentEnvironmentVariablesList(projectName, deploymentName);
  const {
    data: deploymentVersionVariables,
    mutate: deploymentVersionVariablesMutate,
  } = useDeploymentVersionEnvironmentVariablesList(
    projectName,
    deploymentName,
    deploymentVersionName ?? ""
  );

  const onSave = () => {
    setIsUpdating(true);
    setEditRowId(undefined);
    setEditedRow(undefined);
    setCreateMode(false);
  };

  const tableData = useMemo(
    () =>
      envVars
        ? envVars
        : deploymentVersionName
        ? deploymentVersionVariables
        : deploymentVariables,
    [
      deploymentVariables,
      deploymentVersionName,
      deploymentVersionVariables,
      envVars,
    ]
  );

  const createEnvVar = useCallback(
    (data: EnvironmentVariableCreate) => {
      if (deploymentVersionName) {
        deploymentVersionEnvironmentVariablesCreate(
          projectName,
          deploymentName,
          deploymentVersionName,
          data
        )
          .then(() => deploymentVersionVariablesMutate())
          .catch((e) => dispatch(createErrorNotification(e.message)))
          .finally(onSave);
      } else {
        deploymentEnvironmentVariablesCreate(projectName, deploymentName, data)
          .then(() => deploymentVariablesMutate())
          .catch((e) => dispatch(createErrorNotification(e.message)))
          .finally(onSave);
      }
    },
    [
      deploymentName,
      deploymentVariablesMutate,
      deploymentVersionName,
      deploymentVersionVariablesMutate,
      dispatch,
      projectName,
    ]
  );

  const updateEnvVar = useCallback(
    (id: string, data: EnvironmentVariableCreate) => {
      if (deploymentVersionName) {
        deploymentVersionEnvironmentVariablesUpdate(
          projectName,
          deploymentName,
          deploymentVersionName,
          id,
          data
        )
          .then(() => deploymentVersionVariablesMutate())
          .catch((e) => dispatch(createErrorNotification(e.message)))
          .finally(onSave);
      } else {
        deploymentEnvironmentVariablesUpdate(
          projectName,
          deploymentName,
          id,
          data
        )
          .then(() => deploymentVariablesMutate())
          .catch((e) => dispatch(createErrorNotification(e.message)))
          .finally(onSave);
      }
    },
    [
      deploymentName,
      deploymentVariablesMutate,
      deploymentVersionName,
      deploymentVersionVariablesMutate,
      dispatch,
      projectName,
    ]
  );

  const deleteEnvVar = (data: InheritedEnvironmentVariableList) => {
    if (onDelete !== undefined) {
      onDelete(data);
    } else if (deploymentVersionName && data?.id) {
      deploymentVersionEnvironmentVariablesDelete(
        projectName,
        deploymentName,
        deploymentVersionName,
        data?.id
      )
        .then(() => deploymentVersionVariablesMutate())
        .finally(onSave);
    } else if (data?.id) {
      deploymentEnvironmentVariablesDelete(
        projectName,
        deploymentName,
        data?.id
      )
        .then(() => deploymentVariablesMutate())
        .finally(onSave);
    }
  };

  const handleSave = useCallback(() => {
    const oldData = tableData?.find((data) => data.id === editRowId);
    if (createMode) {
      createEnvVar(editedRow as unknown as EnvironmentVariableCreate);
      onAdd?.(editedRow as unknown as InheritedEnvironmentVariableList);

      return;
    }
    if (!oldData || !editedRow) return;
    if (isInherited(oldData)) {
      createEnvVar(editedRow as unknown as EnvironmentVariableCreate);
    } else {
      const formattedNewData = {
        name: editedRow.name,
        value: editedRow.value,
        secret: editedRow.secret,
      };
      onChange
        ? onChange(oldData.id, {
            ...formattedNewData,
            id: getRandomId(),
          })
        : updateEnvVar(oldData.id, formattedNewData);
    }
  }, [
    createEnvVar,
    createMode,
    editRowId,
    editedRow,
    onAdd,
    onChange,
    tableData,
    updateEnvVar,
  ]);

  const columns = useMemo(
    () => [
      ...(includeInheritance && !isUsedInForm
        ? [
            {
              title: "Inherited from",
              field: "inheritance_type",
              width: "15%",
              editable: false,
              nowrap: true,
              render: (rowData: InheritedEnvironmentVariableList) =>
                rowData?.inheritance_type || "-",
            },
          ]
        : []),
      {
        title: "Name",
        field: "name",
        width: "40%",
        nowrap: true,
        editable: true,
        validate: (rowData: InheritedEnvironmentVariableList) => {
          if (!validators.envVarName.pattern.test(rowData.name)) {
            return (
              <WarningText
                component="span"
                variant="h3"
                color={theme.palette.error.main}
                icon={ErrorOutline}
              >
                {validators.envVarName.message}
              </WarningText>
            );
          }

          return false;
        },
      },
      {
        title: "Value",
        field: "value",
        width: includeInheritance || isUsedInForm ? "20%" : "40%",
        nowrap: true,
        editable: true,
        render: (rowData: InheritedEnvironmentVariableList) => (
          <>
            {rowData.secret ? (
              <Icon component={Lock} status="none" />
            ) : (
              <Typography className="env-vars-overview__value">
                {rowData.value}
              </Typography>
            )}
          </>
        ),
      },
      {
        title: "Secret",
        field: "secret",
        width: "10%",
        editable: true,
        lookup: secretValues,
        options: Object.entries(secretValues).map(([value, label]) => ({
          label,
          value,
        })),
        initialEditValue: "false",
        render: (rowData: InheritedEnvironmentVariableList) =>
          secretValues?.[rowData?.secret ? "true" : "false"],
      },
      {
        width: "4%",
        field: "",
        nowrap: true,
        render: (rowData: InheritedEnvironmentVariableList) => {
          const isDeleteDisabled = isInherited(rowData) || !isDeletable;

          return (
            <Grid
              className="actions_container"
              alignItems="flex-end"
              paddingLeft={editRowId === rowData?.id ? spacing[12] : undefined}
            >
              <Tooltip
                title={
                  isInherited(rowData)
                    ? "Overwrite"
                    : editRowId === rowData?.id
                    ? "Save"
                    : "Edit"
                }
              >
                <span>
                  {editRowId === rowData?.id ? (
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="secondary"
                      disabled={
                        isInherited(rowData) ? !isCreatable : !isEditable
                      }
                      onClick={() => {
                        setEditRowId(rowData?.id);
                      }}
                    >
                      {isInherited(rowData) ? <ContentCopy /> : <Edit />}
                    </IconButton>
                  )}
                </span>
              </Tooltip>
              <Tooltip
                style={{
                  paddingLeft:
                    editRowId === rowData?.id ? spacing[4] : undefined,
                }}
                title={
                  isDeleteDisabled
                    ? ""
                    : editRowId === rowData?.id
                    ? "Cancel"
                    : "Delete"
                }
              >
                <span>
                  {editRowId === rowData?.id ? (
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        setEditRowId(undefined);
                        setCreateMode(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="primary"
                      disabled={isDeleteDisabled}
                      onClick={(e) => {
                        e.preventDefault();

                        e.stopPropagation();
                        setSelectedItemToDelete(rowData);
                        setDeleteDialogOpen(true);
                      }}
                    >
                      {isDeleteDisabled ? <TrashOff /> : <Trash />}
                    </IconButton>
                  )}
                </span>
              </Tooltip>
            </Grid>
          );
        },
      },
    ],
    [
      editRowId,
      handleSave,
      includeInheritance,
      isCreatable,
      isDeletable,
      isEditable,
      isUsedInForm,
      theme.palette.error.main,
    ]
  );

  // @ts-expect-error not all code paths return a value
  useEffect(() => {
    // Display a warning if either the row has been edited,
    // a new row has been created or a row has been deleted
    if (!isUsedInForm && isUpdating) {
      setHasChanged(true);

      // Remove warning state after the 30 seconds have passed
      const timeoutId = setTimeout(() => {
        setHasChanged(false);
      }, 30000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isUpdating, isUsedInForm]);

  useEffect(() => {
    if (!editRowId) {
      setEditedRow(undefined);
    }
    if (editRowId && editRowId !== editedRow?.id) {
      const data = tableData?.find(
        ({ id }: { id: string }) => id === editRowId
      );
      setEditedRow(data || { id: "new-row", secret: false });
    }
  }, [editRowId, editedRow?.id, tableData]);

  const rows = createMode
    ? [{ id: "new-row", secret: false }, ...(tableData || [])]
    : tableData;

  return (
    <>
      {hasChanged && warningMessage && (
        <Box mb={spacing[16]}>
          <WarningText color={theme.palette.text.primary}>
            {warningMessage}
          </WarningText>
        </Box>
      )}
      <BaseTable
        columns={columns as unknown as BaseColumn[]}
        data={rows}
        isLoading={isValidating}
        editRowId={editRowId}
        hasSearchField={!isUsedInForm}
        editedRow={editedRow}
        setEditedRow={setEditedRow}
        action={
          <Grid container spacing={2}>
            <Grid item>
              <PrimaryButton
                startIcon={<Plus />}
                disabled={!isCreatable}
                onClick={() => {
                  setCreateMode(true);
                  setEditRowId("new-row");
                }}
              >
                Create variable
              </PrimaryButton>
            </Grid>
            {isCopyable && (
              <Grid item>
                {isUsedInForm ? (
                  <SecondaryButton
                    startIcon={<Copy />}
                    disabled={!isCreatable}
                    onClick={onCopyClick}
                  >
                    Copy existing variables
                  </SecondaryButton>
                ) : (
                  <SecondaryButton
                    startIcon={<Copy />}
                    disabled={!isCreatable}
                    link={`${match.url}/copy`}
                  >
                    Copy existing variables
                  </SecondaryButton>
                )}
              </Grid>
            )}
          </Grid>
        }
      />
      <DeleteDialog
        onDelete={() => {
          if (selectedItemToDelete) {
            deleteEnvVar(selectedItemToDelete);
          }
          setDeleteDialogOpen(false);
        }}
        onClose={() => setDeleteDialogOpen(false)}
        open={deleteDialogOpen}
      >
        This will delete the selected variable. Are you sure?
      </DeleteDialog>
    </>
  );
};
