import AddBox from "@mui/icons-material/AddBox";
import Plus from "@mui/icons-material/AddBoxRounded";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { IlluAddGraph } from "assets/images/IlluAddGraph";
import { IlluEmptyCustomMetricsDialog } from "assets/images/IlluEmptyCustomMetricsDialog";
import { borderRadius, borders, spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { useMetricsList } from "libs/data/endpoints/metrics/metrics";
import { ENV_NAMES, env } from "libs/env";
import { useDeviceDetect } from "libs/hooks";
import { getGraphsWithDynamicUnit } from "libs/utilities/metrics-helper";
import { routes } from "routes";

import {
  Checkbox,
  Dialog,
  ExternalLink,
  PrimaryButton,
  SecondaryButton,
} from "components/atoms";
import { SimpleTabs } from "components/organisms";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { MetricDetail } from "libs/data/models/metricDetail";
import type { MonitoringGraph } from "pages/organizations/:organizationName/projects/:projectName/monitoring/general/types";

type AddGraphBlockProps = {
  visibleGraphs: MonitoringGraph[];
  updateGraphs: (graphs: MonitoringGraph[]) => void;
};
export const AddGraphCard = ({
  visibleGraphs,
  updateGraphs,
}: AddGraphBlockProps) => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [graphs, setGraphs] = useState(visibleGraphs);
  const theme = useTheme() as AppThemeProps;
  const history = useHistory();
  const { isMobile } = useDeviceDetect();
  const savedGraphs = env.get(ENV_NAMES.USER_MONITORING_GRAPHS);
  const { data: allMetrics } = useMetricsList(projectName);
  const onAddGraph = () => {
    const graphsWithDynamicUnit = getGraphsWithDynamicUnit(graphs);
    env.set(ENV_NAMES.USER_MONITORING_GRAPHS, {
      ...savedGraphs,
      [projectName]: graphsWithDynamicUnit,
    });
    updateGraphs(graphsWithDynamicUnit);
    setIsDialogOpen(false);
  };

  const createUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .monitoring.metrics.create.index();

  const customMetricsList = useMemo(
    () =>
      allMetrics &&
      allMetrics.filter((metric: MetricDetail) => metric.custom === true),
    [allMetrics]
  );
  const defaultMetricsList = useMemo(
    () =>
      allMetrics &&
      allMetrics.filter(
        (metric: MetricDetail) =>
          metric.custom === false &&
          !metric.name.includes("deployment_requests")
      ),
    [allMetrics]
  );

  const handleChange = (name: string, checked: boolean) => {
    if (checked) setGraphs(graphs.concat({ title: name, metric: [name] }));
    else setGraphs(graphs.filter((graph) => graph.metric[0] !== name));
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={1}
          border={borders.dotted}
          style={{
            background: theme.palette.background.subtle,
            padding: spacing[16],
            height: "300px",
            width: "100%",
            borderRadius: borderRadius[4],
          }}
        >
          {!isMobile && (
            <Box paddingLeft={3}>
              <IlluAddGraph />
            </Box>
          )}
          <Typography variant="body1" textAlign="center">
            Customize your dashboard by adding up to 10 graphs.
            <br /> Use our handy custom templates or create your own metrics.
          </Typography>
          <PrimaryButton
            startIcon={<Plus />}
            onClick={() => setIsDialogOpen(true)}
          >
            Adjust graphs
          </PrimaryButton>
        </Box>
      </Grid>
      <Dialog
        title="Manage graphs"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        Actions={
          <PrimaryButton startIcon={<AddBox />} onClick={onAddGraph}>
            Adjust graphs
          </PrimaryButton>
        }
      >
        <Typography textAlign={"center"} marginBottom={3}>
          Monitor key metrics with our ready to use templates or create and
          track your own custom metrics.{" "}
          <ExternalLink href={DOC_LINKS.CUSTOM_METRICS}>Read more</ExternalLink>
        </Typography>
        <SimpleTabs
          tabs={[
            {
              title: "Default",
              content: (
                <Box p={isMobile ? 1 : 3}>
                  {defaultMetricsList?.map((metric) => (
                    <Checkbox
                      checked={
                        !!graphs.find(
                          (graph) => graph.metric[0] === metric.name
                        )
                      }
                      disabled={
                        graphs.length === 10 &&
                        !graphs.find((graph) => graph.metric[0] === metric.name)
                      }
                      onChange={(_e, checked) =>
                        handleChange(metric.name, checked)
                      }
                      name="default-metric-checklist"
                      key={metric.name}
                      label={metric.name}
                      color="secondary"
                    />
                  ))}
                </Box>
              ),
            },
            {
              title: "Custom",
              content: (
                <Box p={3}>
                  {customMetricsList && customMetricsList?.length > 0 ? (
                    <>
                      {customMetricsList.map((metric) => (
                        <Checkbox
                          checked={
                            !!graphs.find(
                              (graph) => graph.metric[0] === metric.name
                            )
                          }
                          disabled={
                            graphs.length === 10 &&
                            !graphs.find(
                              (graph) => graph.metric[0] === metric.name
                            )
                          }
                          onChange={(_e, checked) =>
                            handleChange(metric.name, checked)
                          }
                          name="default-metric-checklist"
                          key={metric.name}
                          label={metric.name}
                          color="secondary"
                        />
                      ))}
                      <SecondaryButton
                        startIcon={<AddBox />}
                        style={{ marginTop: "20px" }}
                        onClick={() => history.push(createUrl)}
                      >
                        Create custom metric
                      </SecondaryButton>
                    </>
                  ) : (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <SecondaryButton
                        startIcon={<AddBox />}
                        style={{ marginBottom: "18px" }}
                        onClick={() => history.push(createUrl)}
                      >
                        Create custom metric
                      </SecondaryButton>
                      <IlluEmptyCustomMetricsDialog />
                      <Typography textAlign={"center"} mt={3}>
                        You don’t have any custom metrics defined yet. <br />
                        Create a new one and start tracking!{" "}
                        <ExternalLink>Read more</ExternalLink>
                      </Typography>
                    </Box>
                  )}
                </Box>
              ),
            },
          ]}
        />
      </Dialog>
    </>
  );
};
