import styled from "@emotion/styled";
import Refresh from "@mui/icons-material/AutorenewRounded";
import { Link, Box, LinearProgress, useTheme } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { getBorders } from "assets/styles/theme/borders";
import { LOADING } from "libs/utilities/request-statuses";
import { LogMessage } from "pages/organizations/:organizationName/projects/:projectName/logs/LogMessage";

import { Loader, PrimaryButton } from "components/atoms";

import { Log } from "./Log";
import { LogsLoader } from "./LogsLoader";
import { NoNewLogs } from "./NoNewLogs";
import { loadedFromDateFromLogSet, loadedUntilDateFromLogSet } from "./utils";

import type { LogRecord, LogSet } from "./types";
import type { AppThemeProps } from "assets/styles/theme/theme";

export const Logs = ({
  autoLoading,
  logSet,
  logsStatus,
  onOlderClick,
  onNewerClick,
  onRefresh,
  refresh = true,
  displayNoLogsMessage = true,
  disabled = false,
  isBuildingLogs = false,
}: LogsProps) => {
  const logsContainer = useRef<HTMLDivElement>(null);
  const [currentSet, setCurrentSet] = useState(logSet);
  const [loadingNewerLogs, setLoadingNewerLogs] = useState(false);
  const [loadingOlderLogs, setLoadingOlderLogs] = useState(false);
  const [shouldScrollDown, setShouldScrollDown] = useState(autoLoading);
  const isLoading = logsStatus === LOADING;
  const theme = useTheme() as AppThemeProps;
  useEffect(() => {
    const scrollHeight = logsContainer?.current?.scrollHeight ?? 0;
    if (shouldScrollDown && logsContainer?.current?.scrollTo) {
      logsContainer.current.scrollTo(0, scrollHeight);
    }
    setCurrentSet(logSet);
  }, [logSet, shouldScrollDown, isLoading]);

  const handleMouseWheel = () => {
    const clientHeight = logsContainer?.current?.clientHeight ?? 0;
    const scrollTop = logsContainer?.current?.scrollTop ?? 0;
    const windowHeight = clientHeight + scrollTop;
    const scrollHeight = logsContainer?.current?.scrollHeight ?? 0;

    if (windowHeight >= scrollHeight - 30) {
      setShouldScrollDown(true);
    } else {
      setShouldScrollDown(false);
    }
  };

  const firstLogRecordDate = useMemo(
    () => (currentSet?.logs?.length ? moment(currentSet?.logs[0].date) : null),
    [currentSet?.logs]
  );

  const logs: LogRecord[] | undefined = useMemo(() => {
    const logs = logSet?.logs;
    if (logs?.length) {
      const hasLogAfterProvidedUntil =
        logs[logs.length - 1].date &&
        logSet?.provided_until &&
        moment(logs[logs.length - 1].date).diff(logSet?.provided_until) > 0;

      if (hasLogAfterProvidedUntil) {
        const log = logs.find(
          (log) => moment(log.date).diff(logSet?.provided_until, "second") > 1
        );

        if (log) {
          log.last_in_boundary = true;
        }
      }

      const hasLogBeforeProvidedFrom =
        logs[0].date &&
        logSet?.provided_from &&
        moment(logs[0].date).diff(logSet.provided_from) < 0;

      if (hasLogBeforeProvidedFrom) {
        const log = logs.find(
          (log) => moment(log.date).diff(logSet?.provided_from, "second") < 1
        );

        if (log) {
          log.first_before_boundary = true;
        }
      }
    }

    return logs;
  }, [logSet]);

  const handleOnOlderLogsClick = async () => {
    if (onOlderClick && !loadingOlderLogs) {
      setLoadingOlderLogs(true);
      await onOlderClick();
      setLoadingOlderLogs(false);
    }
  };

  const handleOnNewerLogsClick = async () => {
    if (onNewerClick && !loadingNewerLogs) {
      setLoadingNewerLogs(true);
      await onNewerClick();
      setLoadingNewerLogs(false);
    }
  };

  const lastLogRecordDate = useMemo(
    () =>
      currentSet?.logs?.length
        ? moment(currentSet?.logs[currentSet?.logs.length - 1].date)
        : null,
    [currentSet?.logs]
  );

  const showNoNewerLogsAvailable = useMemo(() => {
    if (currentSet) {
      return currentSet.until_date && !currentSet.loaded_until?.id;
    }

    return false;
  }, [currentSet]);

  const showNoOlderLogsAvailable = useMemo(() => {
    if (currentSet) {
      const loadedFromDate = loadedFromDateFromLogSet(currentSet);

      return (
        loadedFromDate &&
        currentSet.from_date &&
        loadedFromDate.diff(currentSet.from_date, "seconds") <= 0
      );
    }

    return false;
  }, [currentSet]);

  const renderLogs = useMemo(
    () =>
      logs?.map((log) => (
        <Fragment key={log.id}>
          {!!log.first_before_boundary && (
            <LogMessage>Start of selected date and time range</LogMessage>
          )}
          {!!log.last_in_boundary && (
            <LogMessage>End of selected date and time range</LogMessage>
          )}
          <Log item={log} key={log.id} />
        </Fragment>
      )),
    [logs]
  );

  return (
    <>
      <Container
        ref={logsContainer}
        isBuildingLogs={isBuildingLogs}
        onWheel={handleMouseWheel}
        theme={theme}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!isLoading && !loadingOlderLogs && !!onOlderClick && (
              <LoadLogsLink
                style={{
                  backgroundColor: disabled
                    ? theme.palette.neutrals[500]
                    : theme.palette.secondary.main,
                  color: theme.palette.primary.contrastText,
                }}
                onClick={handleOnOlderLogsClick}
                underline="none"
              >
                Load older logs
              </LoadLogsLink>
            )}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{
                margin: currentSet?.logs?.length ? "" : "auto",
                width: "100%",
              }}
            >
              {loadingOlderLogs && <LogsLoader />}
              {currentSet &&
                !currentSet.logs?.length &&
                displayNoLogsMessage && (
                  <NoNewLogs
                    minDate={loadedFromDateFromLogSet(currentSet) ?? null}
                    maxDate={loadedUntilDateFromLogSet(currentSet) ?? null}
                  />
                )}
              {currentSet &&
                !!currentSet.logs?.length &&
                showNoOlderLogsAvailable && (
                  <NoNewLogs
                    minDate={loadedFromDateFromLogSet(currentSet) ?? null}
                    maxDate={firstLogRecordDate}
                  />
                )}

              {renderLogs}

              {currentSet &&
                !!currentSet.logs?.length &&
                showNoNewerLogsAvailable && (
                  <NoNewLogs
                    minDate={lastLogRecordDate}
                    maxDate={loadedUntilDateFromLogSet(currentSet) ?? null}
                  />
                )}
              {currentSet?.logs && !currentSet?.logs?.length && refresh && (
                <PrimaryButton
                  startIcon={<Refresh />}
                  onClick={onRefresh}
                  disabled={disabled}
                >
                  Refresh
                </PrimaryButton>
              )}
            </Box>
            {(autoLoading || loadingNewerLogs) && (
              <Box width={"100%"}>
                <LinearProgress color="secondary" />
              </Box>
            )}
            {!isLoading && !loadingNewerLogs && !!onNewerClick && (
              <LoadLogsLink
                onClick={handleOnNewerLogsClick}
                underline="none"
                style={{
                  backgroundColor: disabled
                    ? theme.palette.neutrals[500]
                    : theme.palette.secondary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                Load newer logs
              </LoadLogsLink>
            )}
          </>
        )}
      </Container>
    </>
  );
};

const LoadLogsLink = styled(Link)`
  display: inline-block;
  padding: 0.3em 0;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
`;

const Container = styled.div<{ isBuildingLogs: boolean; theme: AppThemeProps }>`
  min-height: ${(props) => (props.isBuildingLogs ? "" : "300px")};
  overflow-y: auto;
  border: ${(props) => getBorders(props.theme).primary};
  overflow-x: hidden;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-height: 100%;
`;

type LogsProps = {
  autoLoading: boolean;
  disabled?: boolean;
  logSet?: LogSet;
  logsStatus: string;
  onNewerClick?: () => void;
  onOlderClick?: () => void;
  onRefresh?: () => void;
  refresh?: boolean;
  displayNoLogsMessage?: boolean;
  isBuildingLogs?: boolean;
};
