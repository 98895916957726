import { useEffect } from "react";
import { getBezierPath, useNodes } from "reactflow";

import { usePortal } from "libs/hooks";

import { EdgeSidebar } from "./EdgeSidebar";
import { ActionButtons } from "../ActionButtons";
import { useIsSidebarRightOpen, useSidebarRightState } from "../SidebarRight";
import {
  DIAGRAM_SIDEBAR_PARENT_ID,
  OBJECT_REFERENCE_TYPE_OPERATOR,
  OPERATOR_MANY_TO_ONE,
  OPERATOR_ONE_TO_MANY,
} from "../constants";
import { NodeTypes } from "../types";
import { useDeleteEdge } from "../useDeleteEdge";

import type { NodeDataType } from "../types";
import type { EdgeProps } from "reactflow";

export const EdgeCustom = (edge: EdgeProps) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    selected,
    source,
    target,
    data,
    markerEnd,
  } = edge;
  const deleteEdge = useDeleteEdge();

  const nodes = useNodes<NodeDataType>();
  const sourceNode = nodes.find((node) => node.id === source);
  const targetNode = nodes.find((node) => node.id === target);
  const sourceNodeReferenceType =
    sourceNode?.data.pipelineObject.reference_type;
  const sourceNodeReferenceName =
    sourceNode?.data.pipelineObject.reference_name;
  const targetNodeReferenceType =
    targetNode?.data.pipelineObject.reference_type;
  const targetNodeReferenceName =
    targetNode?.data.pipelineObject.reference_name;

  const Portal = usePortal(document.getElementById(DIAGRAM_SIDEBAR_PARENT_ID));

  const isSubRequestsOperatorEdge =
    (targetNodeReferenceType === OBJECT_REFERENCE_TYPE_OPERATOR &&
      targetNodeReferenceName === OPERATOR_ONE_TO_MANY) ||
    (sourceNodeReferenceType === OBJECT_REFERENCE_TYPE_OPERATOR &&
      sourceNodeReferenceName === OPERATOR_MANY_TO_ONE);
  const isDiamondEdge = targetNode?.type === NodeTypes.diamond;
  const isSidebarOpen = useIsSidebarRightOpen();
  const [isInfoSidebarOpen, setIsInfoSidebarOpen] = useSidebarRightState(id);

  useEffect(() => {
    if (data.new) {
      setIsInfoSidebarOpen(true);
    }
  }, [data.new, id, setIsInfoSidebarOpen]);

  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <Portal>
        <EdgeSidebar
          id={id}
          sourceId={source}
          targetId={target}
          edgeData={data}
          isOpen={isInfoSidebarOpen}
          onClose={() => setIsInfoSidebarOpen(false)}
        />
      </Portal>

      <path
        id={id}
        d={edgePath}
        markerEnd={markerEnd}
        style={{ strokeWidth: 3, ...style }}
        className="react-flow__edge-path"
      />

      <foreignObject
        width={100}
        height={60}
        x={edgeCenterX - 100 / 2}
        y={edgeCenterY - 60 / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div>
          {!!(selected || isInfoSidebarOpen) &&
            !(!isInfoSidebarOpen && !!isSidebarOpen) &&
            !isDiamondEdge && (
              <ActionButtons
                onDeleteClicked={() => deleteEdge(id)}
                onInfoClicked={() =>
                  !isSubRequestsOperatorEdge && setIsInfoSidebarOpen(true)
                }
              />
            )}
        </div>
      </foreignObject>
    </>
  );
};
