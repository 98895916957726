import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { instanceTypeGroupsDelete } from "libs/data/endpoints/instances/instances";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";

export const useInstanceTypeGroupDelete = (projectName: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (groupId: string) => {
      try {
        await instanceTypeGroupsDelete(projectName, groupId);
        dispatch(createSuccessNotification("Instance type group was deleted"));
      } catch (err) {
        dispatch(createErrorNotification((err as AxiosError).message));
      }
    },
    [projectName, dispatch]
  );
};
