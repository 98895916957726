import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/GetApp";
import Search from "@mui/icons-material/Search";
import {
  Grid,
  Box,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";

import { spacing } from "assets/styles/theme";
import { isArrayHasNoData } from "libs/utilities/utils";

import { RefreshButton } from "components/atoms";

import { RequestsFilter } from "./RequestsFilter";

import type { RequestsRows } from "./types";
import type { AppThemeProps } from "assets/styles/theme/theme";

export interface Filter {
  status: string | boolean;
  pipeline?: string | boolean;
  start_date?: string;
  end_date?: string;
}

interface RequestsToolbarProps {
  selectedFilters: Filter;
  setSelectedFilters: (filter: Filter) => void;
  setSearch: (search: string | undefined) => void;
  search: string | undefined;
  requests: RequestsRows | undefined;
  openDownloadDialog: () => void;
  refresh: () => void;
}

export const RequestsToolbar = ({
  selectedFilters,
  setSelectedFilters,
  requests,
  openDownloadDialog,
  setSearch,
  search,
  refresh,
}: RequestsToolbarProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Grid
      container
      alignItems="flex-start"
      flexWrap="wrap"
      style={{ fontSize: "0.9em" }}
    >
      <Grid item xs={12}>
        <RequestsFilter
          setFilters={setSelectedFilters}
          defaultValues={selectedFilters}
        >
          <Box ml={1} alignItems="flex-start">
            <RefreshButton onClick={refresh} tooltip="Refresh table" />
            <Tooltip title="Download requests">
              <span>
                <IconButton
                  color="primary"
                  disabled={isArrayHasNoData(requests || [])}
                  onClick={openDownloadDialog}
                >
                  <DownloadIcon sx={{ color: theme.palette.text.primary }} />
                </IconButton>
              </span>
            </Tooltip>{" "}
          </Box>
        </RequestsFilter>
      </Grid>
      <Grid
        display="flex"
        justifyContent="flex-end"
        paddingTop={spacing[20]}
        item
        xs={12}
      >
        <TextField
          value={search || ""}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: theme.palette.text.secondary }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearch(undefined)}>
                  <ClearIcon sx={{ color: theme.palette.text.secondary }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          id="standard-basic"
          sx={{
            width: 220,
          }}
          variant="standard"
        />
      </Grid>
    </Grid>
  );
};
