import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import { STRUCTURED_TYPE } from "libs/constants/constants";
import { fieldTypesLabels } from "libs/utilities/labels-mapping";

import { Card } from "components/atoms";
import { BaseTable } from "components/molecules";

import type { BaseColumn } from "../BaseTable";
import type { SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { InputOutputFieldDetail } from "libs/data/models";

const columns = [
  { title: "Name", field: "name", nowrap: true },
  {
    title: "Type",
    field: "data_type",
    render: ({ data_type }: { data_type: string }) =>
      (fieldTypesLabels as Record<string, string>)[data_type],
  },
];

interface InputCardProps {
  item: { title: string; icon: OverridableComponent<SvgIconTypeMap> };
  type: string;
  fields?: InputOutputFieldDetail[];
}

const InputCard = ({ item, type, fields }: InputCardProps) => (
  <Card
    title={item.title}
    icon={item.icon}
    iconColor="primary"
    actions={
      <Typography color="secondary" variant="h3">
        {type}
      </Typography>
    }
  >
    {type === STRUCTURED_TYPE && fields?.length ? (
      <BaseTable
        columns={columns as BaseColumn[]}
        data={fields}
        hasPagination={false}
        hasSearchBar={false}
      />
    ) : (
      <Typography variant="caption">No fields</Typography>
    )}
  </Card>
);

InputCard.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
};

export default InputCard;
