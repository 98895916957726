import { Box } from "@mui/material";
import { useCallback, useContext } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  FIELD_PROJECT,
  FIELD_CREDIT_LIMIT,
  FIELD_ADVANCED_PERMISSIONS,
  FIELD_CREDIT_LIMIT_ENABLED,
} from "libs/constants/fields";
import { RootUrlContext } from "libs/contexts";
import { useProjectCreate } from "libs/data/customized/projects/useProjectCreate";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { createId } from "libs/utilities/utils";
import validators from "libs/utilities/validators";

import {
  FormTextField,
  Divider,
  DetailsItem,
  InfoTooltip,
  FormSwitch,
  Dialog,
  PrimaryButton,
} from "components/atoms";
import { ProjectComputeLimitSliderBar } from "components/molecules";

interface AddNewProjectDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AddNewProjectDialog = ({
  onClose,
  open,
}: AddNewProjectDialogProps) => {
  useGoogleAnalytics();
  const { organizationName } = useParams<{ organizationName: string }>();

  const createProject = useProjectCreate(organizationName);
  const projectCreateFormMethods = useForm({ mode: "onBlur" });
  const { control, handleSubmit: handleProjectCreateSubmit } =
    projectCreateFormMethods;

  const rootUrl = useContext(RootUrlContext);

  const handleProjectCreate = useCallback(
    async (data: any) => {
      const response = await createProject({
        name: data[FIELD_PROJECT],
        organization_name: organizationName,
        [FIELD_ADVANCED_PERMISSIONS]: data[FIELD_ADVANCED_PERMISSIONS],
        [FIELD_CREDIT_LIMIT]: data[FIELD_CREDIT_LIMIT_ENABLED]
          ? data[FIELD_CREDIT_LIMIT]
          : null,
      });
      if (response?.name) {
        window.location.pathname = `${rootUrl}/projects/${response.name}`;
      }
    },
    [createProject, organizationName, rootUrl]
  );

  return (
    <FormProvider {...projectCreateFormMethods}>
      <Dialog
        onClose={onClose}
        open={open}
        title="Create new project"
        Actions={
          <PrimaryButton
            onClick={handleProjectCreateSubmit(handleProjectCreate)}
          >
            Create new project
          </PrimaryButton>
        }
      >
        <FormTextField
          id={FIELD_PROJECT}
          name={FIELD_PROJECT}
          label="Project name"
          rules={{
            required: validators.required.message(FIELD_PROJECT),
            pattern: {
              value: validators.name.pattern,
              message: validators.name.message(FIELD_PROJECT),
            },
          }}
          tooltip="The project name is the globally unique identifier for your project."
          defaultValue={`my-project-${createId()}`}
          placeholder="Ex: my-project-1"
        />

        <Box margin="8px 0">
          <DetailsItem
            title="Enable advanced permissions"
            titleVariant="h6"
            icon={
              <InfoTooltip>
                {explanations.projects.advancedPermissions}
              </InfoTooltip>
            }
          >
            <Controller
              render={({ value, onChange, ...params }: any) => (
                <FormSwitch
                  checked={value}
                  onChange={(_: any, data: any) => onChange(data)}
                  {...params}
                />
              )}
              type="checkbox"
              name={FIELD_ADVANCED_PERMISSIONS}
              control={control}
              defaultValue={false}
            />
          </DetailsItem>
        </Box>

        <Box margin="8px 0">
          <Divider />
        </Box>
        <ProjectComputeLimitSliderBar creatingProject />
      </Dialog>
    </FormProvider>
  );
};
