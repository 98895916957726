import Plus from "@mui/icons-material/AddBoxRounded";
import { Box, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { PIPELINE_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { useDeploymentVersionsList } from "libs/data/endpoints/deployments/deployments";
import { useDeviceDetect } from "libs/hooks";
import {
  DATE_TIME_FORMAT,
  getTimeFromNow,
  getTzAwareDate,
} from "libs/utilities/date-util";
import {
  formatLabelsForFilter,
  renderLabels,
} from "libs/utilities/labels-util";
import { useGetCurrentOrganization } from "store/features";
import { useGetPermissions } from "store/features/permissions";

import { Loader, PrimaryButton, TableLink } from "components/atoms";
import { BaseTable } from "components/molecules";
import { onFilterAdd } from "components/organisms";

import { ExperimentEmptyOverview } from "./ExperimentEmptyOverview";
import { TRAINING_DEPLOYMENT } from "../constants";

import type { BaseColumn } from "components/molecules/BaseTable";
import type { DeploymentVersionList } from "libs/data/models";

export const ExperimentsOverview = () => {
  const baseUrl = useContext(BaseUrlContext);
  const { isMobile } = useDeviceDetect();
  const { projectName } = useParams<{ projectName: string }>();
  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);
  const [projectPermissions] = useGetPermissions();
  const organization = useGetCurrentOrganization();

  const { data: experiments, error } = useDeploymentVersionsList(
    projectName,
    TRAINING_DEPLOYMENT,
    { labels: formatLabelsForFilter(filters) },
    {
      swr: {
        swrKey: `/projects/${projectName}/deployments/${TRAINING_DEPLOYMENT}/versions`,
      },
    }
  );

  const history = useHistory();

  const isLoading = !experiments && !error;

  const columns = [
    {
      title: "Name",
      width: "20%",
      field: "version",
      nowrap: true,
      render: ({ version }: DeploymentVersionList) => (
        <TableLink variant="bold" to={`${baseUrl}/experiments/${version}`}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {version}
          </div>
        </TableLink>
      ),
    },
    {
      defaultSort: "desc",
      title: "Created",
      width: "10%",
      field: "creation_date",
      type: "datetime",
      nowrap: true,
      render: (rowData: DeploymentVersionList) =>
        getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
    },
    !isMobile && {
      title: "Edited",
      width: "15%",
      field: "last_updated",
      type: "datetime",
      nowrap: true,
      render: (rowData: DeploymentVersionList) =>
        getTimeFromNow(rowData.last_updated ?? ""),
      defaultSort: "desc",
    },
    !isMobile && {
      title: "Environment",
      width: "20%",
      field: "environment_display_name",
      render: ({ environment_display_name }: DeploymentVersionList) =>
        environment_display_name,
    },
    {
      title: "Labels",
      width: "20%",
      field: "labels",
      render: renderLabels(onFilterAdd(setFilters)),
    },
  ].filter(Boolean);

  const handleCreate = () => {
    history.push(`${baseUrl}/experiments/create`);
  };

  const createEnabled =
    !!projectPermissions[PIPELINE_PERMISSIONS["create"]] &&
    organization?.status === "active";

  return (
    <Grid xs={12} padding={2}>
      {isLoading && <Loader />}
      {!isLoading && experiments?.length === 0 && <ExperimentEmptyOverview />}
      {!isLoading && experiments?.length !== 0 && (
        <Box>
          <BaseTable
            columns={columns as BaseColumn[]}
            data={experiments}
            filters={filters}
            setFilters={setFilters}
            action={
              <PrimaryButton
                startIcon={<Plus />}
                disabled={!createEnabled}
                onClick={handleCreate}
              >
                Create new
              </PrimaryButton>
            }
            defaultSortColumn="creation_date"
            defaultSortDirection="asc"
          />
        </Box>
      )}
    </Grid>
  );
};
