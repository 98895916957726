import { PageHeader } from "components/molecules/PageLayout";
import { useCreateInstanceTypeGroup } from "libs/data/customized/instance-type-groups/useInstanceTypeGroupCreate";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { InstanceTypeGroupBaseForm } from "./InstanceTypeGroupBaseForm";

export const InstanceTypeGroupCreate = () => {
  const handleOnSubmit = useCreateInstanceTypeGroup();

  return (
    <PageContainer>
      <PageHeader title="Create new instance type group" />
      <FormWrapper>
        <InstanceTypeGroupBaseForm onSubmit={handleOnSubmit} />
      </FormWrapper>
    </PageContainer>
  );
};
