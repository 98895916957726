import { rgba } from "polished";

import { colors } from "./colors";

import type { PaletteMode } from "@mui/material";

export const lightPalette = {
  type: "light",
  mode: "light" as PaletteMode,
  primary: {
    main: colors.royalBlue,
    light: colors.midnightBlue,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.tangerine,
    light: colors.pomelo,
    contrastText: colors.white,
  },
  tertiary: {
    main: colors.causeway,
    light: colors.wisteria,
  },
  error: {
    main: colors.picassoRed,
    contrastText: colors.white,
    dark: colors.russianRed,
    light: colors.chalkRed,
  },
  warning: {
    main: colors.submarineYellow,
    contrastText: colors.white,
    dark: colors.burntToast,
    light: colors.vanillaCreamYellow,
  },
  success: {
    main: colors.energiseGreen,
    contrastText: colors.white,
    dark: colors.aspenGreen,
    light: colors.mistyLakeGreen,
  },
  info: {
    main: colors.denim,
    light: colors.skyBlue,
    contrastText: colors.white,
    dark: colors.windstormBlue,
  },
  logs: {
    background: colors.mist,
    hover: colors.pomelo,
  },
  text: {
    primary: colors.royalBlue,
    secondary: rgba(colors.royalBlue, 0.54),
    tertiary: colors.mist,
    emphasis: colors.tangerine,
    disabled: rgba(colors.royalBlue, 0.38),
    hint: rgba(colors.royalBlue, 0.38),
    formLabel: colors.mist,
  },
  action: {
    active: rgba(colors.royalBlue, 0.54),
    hover: colors.mist,
    selected: rgba(colors.royalBlue, 0.14),
    disabled: colors.cerebralGrey,
    disabledBackground: colors.quickSilver,
  },
  neutrals: {
    900: colors.pitchBlack,
    500: colors.smokeGrey,
    400: colors.slabGrey,
    300: colors.cerebralGrey,
    200: colors.mist,
    100: colors.ghost,
    50: colors.moonWhite,
    0: colors.paperWhite,
  },
  charts: {
    legend: colors.royalBlue,
    ticks: colors.royalBlue,
    crosshair: colors.cerebralGrey,
    grid: colors.mist,
  },
  chip: {
    date: colors.frost,
  },
  disabled: {
    primary: colors.cerebralGrey,
    secondary: colors.quickSilver,
    tertiaty: colors.ghost,
    dark: colors.slabGrey,
  },
  pipelineDiagram: {
    backgroundLine: colors.cerebralGrey,
    tab: colors.smokeGrey,
    nodeBackground: colors.ghost,
    nodeHover: colors.mist,
    deploymentAccent: colors.green,
    pipelineAccent: colors.heirloomBlue,
    startEndNodesBackground: colors.paperWhite,
    startEndNodesText: colors.smokeGrey,
    controlsBackground: colors.royalBlue,
    controlsIcons: colors.white,
    controlsBorder: colors.mist,
    dialogBackground: colors.paperWhite,
    dialogBorder: colors.slabGrey,
    sidebarButton: colors.royalBlue,
    operatorContainer: colors.paperWhite,
    operatorHover: colors.mist,
    cardDragIndicator: colors.slabGrey,
    selectMultiLabel: colors.mist,
    selectMultiLabelText: colors.royalBlue,
    dateSaved: colors.cerebralGrey,
    varDetails: colors.paperWhite,
  },
  background: {
    default: colors.paperWhite,
    paper: colors.paperWhite,
    active: colors.mist,
    subtle: colors.ghost,
    codeBlock: colors.mist,
    auth: colors.ghost,
    tableRow: colors.ghost,
  },
  body: {
    background: colors.paperWhite,
    componentBackground: colors.paperWhite,
    text: colors.pitchBlack,
    floatingChip: colors.frost,
    radioHover: colors.pomelo,
  },
  sidebarMenu: {
    organization: colors.midnightBlue,
    organizationText: colors.paperWhite,
    background: colors.ghost,
    active: colors.pomelo,
    hover: colors.mist,
    contrastText: colors.paperWhite,
    chevronButton: colors.ghost,
    chevronColor: colors.causeway,
  },
  border: {
    primary: colors.borders.primary,
    secondary: colors.borders.darkGrey,
    tertiary: colors.slabGrey,
    input: colors.mist,
    bold: colors.tangerine,
    card: colors.cerebralGrey,
    divider: colors.ghost,
  },
  list: {
    item: {
      background: {
        selected: colors.pomelo,
        hover: colors.mist,
      },
    },
  },
  select: {
    option: {
      background: {
        selected: colors.royalBlue,
      },
    },
    multiValue: colors.mist,
  },
  buttons: {
    toggleButtonHover: colors.mist,
    toggleButtonBorder: colors.cerebralGrey,
    tertiaryBackground: colors.mist,
    tertiaryColor: colors.royalBlue,
    disabled: colors.mist,
  },
  stepper: {
    inactiveCircle: colors.cerebralGrey,
  },
  input: {
    label: {
      background: colors.white,
    },
  },
  font: {
    link: colors.link,
    cardHeader: colors.royalBlue,
    primary: colors.royalBlue,
    secondary: colors.smokeGrey,
  },
  shadow: {
    primary: colors.shadows.primary,
    secondary: colors.shadows.secondary,
  },
  table: {
    nameColumn: colors.royalBlue,
    comparison: {
      fixed: colors.ghost,
      scroll: colors.paperWhite,
    },
    deleteIcon: colors.royalBlue,
    searchIcon: colors.slabGrey,
  },
  draggable: {
    background: colors.moonWhite,
  },
  specials: {
    deployment: colors.green,
    pipelines: colors.heirloomBlue,
    blue: {
      bright: colors.heirloomBlue,
      pastel: colors.frost,
    },
    lilac: {
      bright: colors.orchid,
      pastel: colors.mulberry,
    },
    yellow: {
      bright: colors.goldenSnitch,
      pastel: colors.solsticeYellow,
    },
    acqua: {
      bright: colors.peppermint,
      pastel: colors.menthol,
    },
    red: {
      bright: colors.coral,
      pastel: colors.rosewood,
    },
    purple: {
      bright: colors.amethyst,
      pastel: colors.grapeSoda,
      darkPastel: colors.lavenderHaze,
    },
    verdent: {
      bright: colors.lawn,
      pastel: colors.fennel,
    },
    orange: {
      bright: colors.pumpkinSpiceLatte,
      pastel: colors.chestnut,
    },
    violet: {
      bright: colors.thistle,
      pastel: colors.petunia,
    },
    turquoise: {
      bright: colors.glacierBlue,
      pastel: colors.saltSpray,
    },
    pink: {
      bright: colors.phloxPink,
      pastel: colors.berry,
    },
    green: {
      bright: colors.lizardGreen,
      pastel: colors.spring,
    },
  },
  header: {
    color: colors.white,
    background: colors.royalBlue,
    search: {
      color: colors.white,
    },
    indicator: {
      background: colors.picassoRed,
    },
  },
  svgs: {
    pair1: colors.f5,
    pair2: colors.mediumGrey,
    pair3: colors.f5,
    pair4: colors.softGrey,
    pair5: colors.royalBlue,
  },
};

export type PaletteProps = typeof lightPalette;
