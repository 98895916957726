import CloudDownload from "@mui/icons-material/CloudDownloadRounded";
import { Tooltip, IconButton } from "@mui/material";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { IlluRevisions } from "assets/images/IlluRevisions";
import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { useDeploymentVersionRevisionDownload } from "libs/data/customized/deployment-version-revisions/useDeploymentVersionRevisionDownload";
import { useDeploymentVersionRebuild } from "libs/data/customized/deployment-versions/useDeploymentVersionRebuild";
import { usePermissionValidation } from "libs/data/customized/roles";
import {
  useDeploymentVersionsGet,
  useRevisionsList,
} from "libs/data/endpoints/deployments/deployments";
import { useDeviceDetect, useGoogleAnalytics, useInterval } from "libs/hooks";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { formatStatusLabel } from "libs/utilities/statuses";

import {
  HighlightedText,
  RetryIconSpinner,
  StatusIcon,
} from "components/atoms";
import { BaseTable, EmptyOverview } from "components/molecules";

import type { BaseColumn } from "components/molecules/BaseTable";
import type { RevisionList } from "libs/data/models";

export const DeploymentVersionRevisions = () => {
  useGoogleAnalytics();
  const { isMobile } = useDeviceDetect();
  const [selectedRevisionId, setSelectedRevisionId] =
    useState<undefined | string>(undefined);
  const { projectName, deploymentName, versionName } =
    useParams<{
      organizationName: string;
      projectName: string;
      deploymentName: string;
      versionName: string;
    }>();

  const downloadRevision = useDeploymentVersionRevisionDownload(
    projectName,
    deploymentName,
    versionName
  );

  const { data: version, mutate: mutateVersions } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    versionName
  );

  const {
    data: revisions,
    error,
    mutate: mutateList,
  } = useRevisionsList(projectName, deploymentName, versionName);

  const isLoading = !revisions && !error;

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  const latestRevision = useMemo(() => {
    return revisions?.sort(
      (a, b) => moment(b.creation_date).unix() - moment(a.creation_date).unix()
    )?.[0];
  }, [revisions]);

  useInterval(
    () => {
      if (latestRevision?.status === "building") {
        mutateList();
        mutateVersions();
        setSelectedRevisionId(undefined);
      }
    },
    [latestRevision, mutateList, mutateVersions],
    CONTINUOUS_REQUEST_DELAY
  );

  const rebuildVersion = useDeploymentVersionRebuild(
    projectName,
    deploymentName,
    versionName
  );

  const handleVersionRebuild = useCallback(
    async (id: string) => {
      setSelectedRevisionId(id);
      await rebuildVersion(id);
    },
    [rebuildVersion]
  );

  const columns = useMemo(
    () =>
      [
        !isMobile && {
          title: "Created",
          field: "creation_date",
          type: "datetime",
          getSortDirection: () => "desc",
          render: ({ creation_date }: RevisionList) =>
            getTzAwareDate(creation_date).format(DATE_TIME_FORMAT),
        },
        !isMobile && {
          title: "Created by",
          field: "created_by",
        },

        {
          title: "Status",
          field: "status",
          render: (rowData: RevisionList) =>
            rowData?.status ? (
              <StatusIcon
                label={formatStatusLabel(rowData.status)}
                status={rowData.status}
                displayError={true}
                errorMessage={rowData.error_message}
                animation={false}
              />
            ) : (
              ""
            ),
        },
        {
          title: "Revision ID",
          field: "id",
        },
        {
          title: "",
          field: "",
          render: ({ id }: RevisionList) =>
            id === version?.active_revision && (
              <HighlightedText>active revision</HighlightedText>
            ),
          align: "right",
        },
        {
          title: "",
          field: "",
          render: (rowData: RevisionList) => (
            <div className="actions_container">
              {rowData && (
                <>
                  <Tooltip title="Download package">
                    <IconButton
                      color="secondary"
                      disabled={
                        !currentPermissions[
                          DEPLOYMENT_PERMISSIONS["version_download"]
                        ]
                      }
                      onClick={() => {
                        downloadRevision(rowData?.id ?? "");
                      }}
                    >
                      <CloudDownload />
                    </IconButton>
                  </Tooltip>

                  <RetryIconSpinner
                    onClick={() => handleVersionRebuild(rowData.id as string)}
                    spinCondition={
                      revisions &&
                      latestRevision?.status === "building" &&
                      rowData.id == selectedRevisionId
                    }
                    disabled={
                      (revisions &&
                        latestRevision?.status === "building" &&
                        selectedRevisionId !== rowData.id) ||
                      !currentPermissions[
                        DEPLOYMENT_PERMISSIONS["version_update"]
                      ]
                    }
                    tooltipText="Rebuild"
                  />
                </>
              )}
            </div>
          ),
        },
      ].filter(Boolean),
    [
      version?.active_revision,
      currentPermissions,
      downloadRevision,
      handleVersionRebuild,
      revisions,
      latestRevision,
      selectedRevisionId,
      isMobile,
    ]
  );

  return (
    <>
      {!isLoading && !revisions?.length ? (
        <EmptyOverview
          message="No revisions to display."
          Illustration={IlluRevisions}
        />
      ) : (
        <BaseTable columns={columns as BaseColumn[]} data={revisions} />
      )}
    </>
  );
};
