import Plus from "@mui/icons-material/AddBoxRounded";
import { isEmpty } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { useDeploymentsList } from "libs/data/endpoints/deployments/deployments";
import { useDeviceDetect, useGoogleAnalytics } from "libs/hooks";
import {
  getTzAwareDate,
  DATE_TIME_FORMAT,
  getTimeFromNow,
} from "libs/utilities/date-util";
import {
  formatLabelsForFilter,
  renderLabels,
} from "libs/utilities/labels-util";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";
import { useGetCurrentOrganization } from "store/features";
import { useGetPermissions } from "store/features/permissions";

import {
  ButtonGroup,
  TableLink,
  PrimaryButton,
  Loader,
} from "components/atoms";
import { BaseTable } from "components/molecules";
import { onFilterAdd } from "components/organisms";

import { DeploymentEmptyOverview } from "./DeploymentEmptyOverview";

import type { BaseColumn } from "components/molecules/BaseTable";
import type { DeploymentList } from "libs/data/models";
import type { ProjectDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/types";

export const DeploymentsOverview = () => {
  useGoogleAnalytics();
  const history = useHistory();
  const { isMobile } = useDeviceDetect();
  const baseUrl = useContext(BaseUrlContext);
  const { projectName } = useParams<ProjectDetailsRouteParams>();

  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);

  const { data: deployments, error } = useDeploymentsList(
    projectName,
    filters?.length
      ? {
          labels: formatLabelsForFilter(filters),
        }
      : undefined,
    {
      swr: {
        swrKey: `/projects/${projectName}/deployments`,
      },
    }
  );

  const organization = useGetCurrentOrganization();

  const isLoadingDeployments = !error && !deployments;

  const [projectPermissions] = useGetPermissions();

  const onRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowData: DeploymentList
  ) => {
    const url = `${baseUrl}/${rowData.name}`;
    if (e.metaKey || e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  const columns = useMemo(
    () =>
      [
        {
          title: "Name",
          field: "name",
          nowrap: true,
          render: (rowData: DeploymentList) => (
            <TableLink
              variant="bold"
              to={`${baseUrl}/${rowData.name}`}
              color={
                rowData.name === TRAINING_DEPLOYMENT
                  ? "textSecondary"
                  : "primary"
              }
            >
              {rowData.name}
            </TableLink>
          ),
        },
        !isMobile && {
          title: "Input type",
          field: "input_type",
          width: "8%",
        },
        !isMobile && {
          title: "Output type",
          field: "output_type",
          width: "8%",
        },
        !isMobile && {
          title: "Created",
          field: "creation_date",
          type: "datetime",
          nowrap: true,
          width: "15%",
          render: (rowData: DeploymentList) =>
            getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
        },
        {
          title: "Edited",
          field: "last_updated",
          type: "datetime",
          nowrap: true,
          width: isMobile ? "30%" : "15%",
          render: (rowData: DeploymentList) =>
            getTimeFromNow(rowData.last_updated as string),
        },
        {
          title: "Labels",
          field: "labels",
          width: "20%",
          nowrap: true,
          render: renderLabels(onFilterAdd(setFilters)),
        },
      ].filter(Boolean),
    [baseUrl, isMobile]
  );

  return (
    <DetailsContainer
      title="Deployments"
      actions={
        <ButtonGroup>
          <PrimaryButton
            startIcon={<Plus />}
            link={`${baseUrl}/create`}
            disabled={
              !projectPermissions[DEPLOYMENT_PERMISSIONS["create"]] ||
              organization?.status !== "active"
            }
            style={{ marginLeft: "auto" }}
          >
            Create
          </PrimaryButton>
        </ButtonGroup>
      }
    >
      {isLoadingDeployments ? (
        <Loader />
      ) : deployments?.length === 0 && isEmpty(filters) ? (
        <DeploymentEmptyOverview
          baseUrl={baseUrl}
          permission={
            !!projectPermissions[DEPLOYMENT_PERMISSIONS["create"]] &&
            organization?.status === "active"
          }
        />
      ) : (
        <BaseTable
          columns={columns as BaseColumn[]}
          data={deployments}
          onRowClick={onRowClick}
          filters={filters}
          setFilters={setFilters}
          defaultSortColumn="last_updated"
          defaultSortDirection="asc"
        />
      )}
    </DetailsContainer>
  );
};
