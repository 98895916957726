import Email from "@mui/icons-material/Email";
import { Grid, TablePagination, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { getInstanceEnvironmentIcon } from "components/organisms/ActiveInstances/utils";
import { useInstanceTypesList } from "libs/data/endpoints/instances/instances";
import { explanations } from "libs/utilities/explanations";

import { Card, Icon, PrimaryButton } from "components/atoms";
import { BaseTable } from "components/molecules";

import type { ProjectDetailsRouteParams } from "../../types";
import type { BaseColumn } from "components/molecules/BaseTable";
import type { InstanceTypeList } from "libs/data/models";

export const InstanceTypes = () => {
  const { projectName } = useParams<ProjectDetailsRouteParams>();
  const [limit, setLimit] = useState(20);
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(0);

  const { data: currentInstanceTypes } = useInstanceTypesList(projectName, {
    cursor: cursor
      ? (new URL(cursor)?.searchParams?.get("cursor") as string)
      : undefined,
    limit,
  });

  const columns = [
    {
      title: "Instance",
      field: "display_name",
      nowrap: true,
      width: "30%",
      render: (rowData: InstanceTypeList) => (
        <Typography variant="h5">{rowData.display_name}</Typography>
      ),
    },
    {
      title: "Reference name",
      tooltip: explanations.instanceTypes.referenceName,
      width: "20%",
      field: "name",
    },
    {
      title: "Credit rate",
      field: "credit_rate",
    },
    {
      title: "Disk space",
      field: "storage",
    },
    {
      title: "Environment",
      field: "cluster_type",
      nowrap: true,
      width: "20%",
      render: (rowData: InstanceTypeList) => (
        <Icon
          component={getInstanceEnvironmentIcon(
            rowData.node_pool?.cluster?.type
          )}
          style={{ height: 25, width: 25 }}
        />
      ),
    },
  ];

  return (
    <Grid xs={12}>
      <Card>
        <BaseTable
          columns={columns as BaseColumn[]}
          data={currentInstanceTypes?.results}
          hasPagination={false}
          action={
            <PrimaryButton
              startIcon={<Email />}
              size="small"
              href={encodeURI(
                `mailto:support@ubiops.com?subject=Request for additional instance types for project ${projectName}`
              )}
            >
              Request More
            </PrimaryButton>
          }
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={-1}
          labelDisplayedRows={({ from, to }) => {
            const rows = currentInstanceTypes?.results.length || 0;
            const rowCount = rows < limit ? from + rows - 1 : to;

            return `${from}-${rowCount}`;
          }}
          rowsPerPage={limit}
          page={page}
          onRowsPerPageChange={(event) => {
            setLimit?.(Number(event.target.value || 20));
            setPage(0);
            setCursor(undefined);
          }}
          onPageChange={(_event, newPage: number) => {
            if (newPage > page) setCursor(currentInstanceTypes?.next as string);
            if (newPage < page)
              setCursor(currentInstanceTypes?.previous as string);
            setPage(newPage);
          }}
          nextIconButtonProps={{
            disabled: !currentInstanceTypes?.next,
          }}
        />
      </Card>
    </Grid>
  );
};
