import { Tabs, Tab, Card } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useCallback, useState } from "react";

import { borders } from "assets/styles/theme";

import type { ReactElement } from "react";

const TabPanel = ({
  children,
  activeTab,
  tabIndex,
}: {
  children: ReactElement;
  activeTab: number;
  tabIndex: number;
}) => (activeTab === tabIndex ? children : null);

const useStyles = makeStyles({
  tab: {
    borderBottom: borders.secondary,
  },
});

type SimpleTabsProps = {
  tabs: { title: string; content: ReactElement }[];
  onChange?: () => void;
  elevation?: number;
};

const SimpleTabs = ({ tabs, onChange, elevation = 0 }: SimpleTabsProps) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = useCallback(
    (_, idx) => {
      if (onChange) onChange();
      setTabIndex(idx);
    },
    [onChange]
  );

  return (
    <Card variant="outlined" elevation={elevation}>
      <Tabs
        variant="scrollable"
        indicatorColor="secondary"
        textColor="secondary"
        value={tabIndex}
        onChange={handleChange}
      >
        {tabs.map(({ title }, index) => (
          <Tab
            key={index}
            label={title}
            className={classes.tab}
            style={{ flexGrow: 1 }}
          />
        ))}
      </Tabs>

      {tabs.map(({ content }, index) => (
        <TabPanel key={index} tabIndex={index} activeTab={tabIndex}>
          {content}
        </TabPanel>
      ))}
    </Card>
  );
};

export default SimpleTabs;
