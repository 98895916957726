import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, IconButton, useTheme } from "@mui/material";

import { shadows } from "assets/styles/theme";

import type { Theme } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme";
import type { CSSProperties, ReactNode } from "react";

export type SidebarLeftExpandableProps = {
  isExpanded: boolean;
  toggleExpand: () => void;
  closeWhenCollapsed?: boolean;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
};

export const SidebarLeftExpandable = ({
  isExpanded,
  toggleExpand,
  closeWhenCollapsed = false,
  style,
  className,
  children,
}: SidebarLeftExpandableProps) => {
  const theme = useTheme() as AppThemeProps & Theme;

  return (closeWhenCollapsed && !isExpanded) || !children ? null : (
    <Box
      component="aside"
      bgcolor={theme.palette.background.default}
      display="flex"
      flexDirection="column"
      height="100%"
      boxShadow={shadows.primaryLight}
      maxWidth={320}
      style={style}
      className={className}
    >
      {children}
      <IconButton
        onClick={toggleExpand}
        sx={{
          left: isExpanded ? "308px" : "37px",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "25px",
          width: "25px",
          top: "178px",
          backgroundColor: theme.palette.sidebarMenu.chevronButton,
          color: theme.palette.sidebarMenu.chevronColor,
          zIndex: "3",
          transition: theme.transitions.create("left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          "&.MuiIconButton-root:hover": {
            backgroundColor: theme.palette.tertiary.light,
            color: theme.palette.text.primary,
          },
        }}
      >
        {isExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </Box>
  );
};
