import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/ErrorOutlineRounded";
import LogsIcon from "@mui/icons-material/SubjectRounded";
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  Box,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import {
  BUILDING_DELAY,
  CONTINUOUS_REQUEST_DELAY,
  RANGE_LOGS_QUERY,
  UPLOAD_TASK,
} from "libs/constants/constants";
import { DOC_LINKS } from "libs/constants/documentation-links";
import {
  useDeploymentVersionsActivityGet,
  useDeploymentVersionsGet,
  useRevisionsGet,
} from "libs/data/endpoints/deployments/deployments";
import { useEnvironmentsGet } from "libs/data/endpoints/environments/environments";
import { RevisionListStatus } from "libs/data/models";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { useGoogleAnalytics, useLogsUrl, useInterval } from "libs/hooks";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import {
  requestRetentionModes,
  requestRetentionTimes,
  getLabel,
} from "libs/utilities/labels-mapping";
import { formatLabels } from "libs/utilities/labels-util";
import { LOADED, LOADING } from "libs/utilities/request-statuses";
import { formatStatusLabel } from "libs/utilities/statuses";
import { Logs } from "pages/organizations/:organizationName/projects/:projectName/logs";
import { loadLogs } from "pages/organizations/:organizationName/projects/:projectName/logs/utils";
import { routes } from "routes";
import { useTaskManager } from "store/features/taskManager";

import {
  StatusIcon,
  Alert,
  Icon,
  DetailsItem,
  Card,
  ExternalLink,
  Link,
  Dialog,
  OverflowTooltip,
  CopyToClipboardButton,
} from "components/atoms";
import {
  DescriptionBlock,
  UploadPackageButton,
  EndpointUrlCopy,
  NotificationGroupsInfo,
} from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { LogsCreate } from "libs/data/models";
import type { DeploymentVersionDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/versions/:versionName/types";
import type { LogRecord } from "pages/organizations/:organizationName/projects/:projectName/logs/types";
import type { UploadTask } from "store/features/taskManager";

export const DeploymentVersionGeneral = () => {
  useGoogleAnalytics();
  const theme = useTheme() as AppThemeProps;
  const match = useRouteMatch();
  const { versionName, deploymentName, projectName, organizationName } =
    useParams<DeploymentVersionDetailsRouteParams>();

  const { tasks } = useTaskManager();

  const [isLogsDialogOpen, setIsLogsDialogOpen] = useState(false);
  const [logs, setLogs] = useState<LogRecord[]>([]);
  const [watchTasks, setWatchTasks] = useState(false);

  const logsUrl = useLogsUrl({
    queryParameters: {
      deployment_version_revision_id: "",
    },
  });

  const { data: version, mutate: versionMutate } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    versionName
  );

  const currentlyUploading = useMemo((): boolean => {
    if (!version || !tasks?.length) return false;

    return !!tasks.filter(
      (task) =>
        (task as UploadTask).version?.id === version.id &&
        task.status === LOADING &&
        task.type === UPLOAD_TASK
    )?.length;
  }, [tasks, version]);

  const { data: revision, mutate: revisionMutate } = useRevisionsGet(
    projectName,
    deploymentName,
    versionName,
    version?.latest_revision as string
  );

  const { data: lastRequest } = useDeploymentVersionsActivityGet(
    projectName,
    deploymentName,
    versionName
  );

  const {
    status,
    id,
    creation_date,
    last_updated,
    last_file_upload,
    maximum_idle_time,
    maximum_instances,
    minimum_instances,
    description,
    monitoring,
    default_notification_group,
    request_retention_mode,
    request_retention_time,
    active_revision,
    maximum_queue_size_express = 1,
    maximum_queue_size_batch = 1,
    static_ip,
    environment_display_name,
    instance_type_group_name,
  } = version ?? ({} as any);

  const { data: environment } = useEnvironmentsGet(
    projectName,
    version?.environment ?? ""
  );

  const endpointUrl = `${env.get(
    ENV_NAMES.HOST
  )}/projects/${projectName}/deployments/${deploymentName}/versions/${versionName}`;

  useEffect(() => {
    if (currentlyUploading || watchTasks) {
      setWatchTasks(true);
      versionMutate().then(() => revisionMutate());
    }
    // We use the watchTasks state as an easy way to tell the component to mutate upon finishing loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyUploading, id, revisionMutate, versionMutate]);

  const loadVersionLogs = useCallback(
    async (params: LogsCreate) => {
      const newLogs = await loadLogs(projectName, {
        ...params,
        filters: {
          deployment_version_revision_id: revision?.id as string,
          deployment_name: deploymentName,
          deployment_version: versionName,
        },
        limit: 100,
      });
      if (newLogs.length) setLogs(logs ? logs.concat(newLogs) : newLogs);
    },
    [deploymentName, logs, projectName, revision?.id, versionName]
  );

  const logsLoading = useMemo(() => {
    return (
      revision &&
      revision?.status !== RevisionListStatus.success &&
      revision?.status !== RevisionListStatus.failed &&
      isLogsDialogOpen
    );
  }, [isLogsDialogOpen, revision]);

  const portForwarding = useMemo(() => version?.ports?.[0], [version]);

  useInterval(
    () => {
      if (logsLoading) {
        if (logs?.length) {
          loadVersionLogs({
            id: logs[logs.length - 1].id,
            date_range: RANGE_LOGS_QUERY,
          });
        } else {
          loadVersionLogs({
            date: moment().toISOString(),
            date_range: -3600,
          });
        }
      }
    },
    [logsLoading, logs, loadVersionLogs],
    CONTINUOUS_REQUEST_DELAY,
    true
  );

  useInterval(
    () => {
      const createdRecently =
        moment().diff(version?.creation_date, "seconds") < 30;

      const editedRecently =
        moment().diff(version?.last_updated, "seconds") < 30;

      if (
        createdRecently ||
        editedRecently ||
        revision?.status === RevisionListStatus.building
      ) {
        revisionMutate();
      }
      if (
        createdRecently ||
        editedRecently ||
        status === RevisionListStatus.building
      ) {
        versionMutate();
      }
    },
    [revisionMutate, version, versionMutate, status, revision?.status],
    BUILDING_DELAY,
    true
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>General</BreadcrumbsItem>
      {revision?.error_message && (
        <Alert variant="filled" severity="error">
          Last revision failed: {revision?.error_message}{" "}
          {revision?.error_message === "Could not find the deployment file" && (
            <>
              Have a look at the{" "}
              <ExternalLink href={DOC_LINKS.DEPLOYMENT_ZIP}>
                deployment package requirements.
              </ExternalLink>
            </>
          )}
          {!!logs.length && (
            <Link to={`${logsUrl}${revision?.id}`}>View logs</Link>
          )}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <DetailsItem title="Status">
              <StatusIcon
                label={
                  status === "unavailable" && currentlyUploading
                    ? "uploading"
                    : formatStatusLabel(status)
                }
                status={
                  status === "unavailable" && currentlyUploading
                    ? "uploading"
                    : formatStatusLabel(status)
                }
              />
            </DetailsItem>
            <DetailsItem title="ID">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <OverflowTooltip title={id}>{id}</OverflowTooltip>
                <CopyToClipboardButton
                  defaultLabel="Copy ID"
                  contentToCopy={id}
                  size="small"
                  htmlColor="secondary"
                />
              </Box>
            </DetailsItem>
            {(revision?.status === RevisionListStatus.building ||
              revision?.status === RevisionListStatus.queued) && (
              <DetailsItem title="Latest revision status">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <StatusIcon
                    label={revision?.status ?? ""}
                    status={revision?.status ?? ""}
                  />

                  <Tooltip title="Building logs">
                    <Button
                      size="small"
                      color="secondary"
                      startIcon={<LogsIcon />}
                      component="button"
                      onClick={() => setIsLogsDialogOpen(true)}
                    >
                      Logs
                    </Button>
                  </Tooltip>
                </Box>
              </DetailsItem>
            )}
            <DetailsItem title="Environment">
              {environment && environment?.base_environment ? (
                <Link
                  to={routes.organizations[":organizationName"](
                    organizationName
                  )
                    .projects[":projectName"](projectName)
                    .environments.custom[":environmentName"](
                      version?.environment as string
                    )
                    .details.general.index()}
                >
                  {environment_display_name}
                </Link>
              ) : (
                <Typography>{environment_display_name}</Typography>
              )}
            </DetailsItem>
            <DetailsItem title="Created">
              <Typography>
                {getTzAwareDate(creation_date).format(DATE_TIME_FORMAT)}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Edited">
              <Typography>
                {getTzAwareDate(last_updated).format(DATE_TIME_FORMAT)}
              </Typography>
            </DetailsItem>
            <DetailsItem
              title="Last file upload"
              tooltip={explanations.deployments.versions.lastDeployment}
            >
              <Typography>
                {last_file_upload
                  ? getTzAwareDate(last_file_upload).format(DATE_TIME_FORMAT)
                  : "-"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Last request">
              <Typography>
                {lastRequest?.last_request
                  ? getTzAwareDate(lastRequest.last_request).format(
                      DATE_TIME_FORMAT
                    )
                  : "-"}
              </Typography>
            </DetailsItem>
            <EndpointUrlCopy url={endpointUrl} />
            <NotificationGroupsInfo
              monitoring={monitoring}
              defaultNotificationGroup={default_notification_group}
            />
            <Grid container spacing={4} justifyContent="center">
              <Grid item>
                <UploadPackageButton
                  versionName={versionName}
                  deploymentVersionId={id}
                  deploymentVersionStatus={status}
                  activeRevision={active_revision}
                  lastBuildStatus={revision?.status as string}
                  lastBuildId={revision?.id as string}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <DetailsItem
              title="Instance type group"
              tooltip={explanations.deployments.versions.instanceType}
            >
              <Link
                to={routes.organizations[":organizationName"](organizationName)
                  .projects[":projectName"](projectName)
                  .projectSettings.instanceTypeGroups.index()}
              >
                {instance_type_group_name}
              </Link>
            </DetailsItem>
            <DetailsItem
              title="Minimum instances"
              tooltip={explanations.deployments.versions.minInstances}
            >
              <Box component={Typography} display="flex" alignItems="center">
                {minimum_instances}
                {minimum_instances > 0 && (
                  <Tooltip
                    title={`This deployment version is always running (minimum instances = ${minimum_instances})`}
                  >
                    <Box
                      component="span"
                      marginLeft={1}
                      display="flex"
                      alignItems="center"
                    >
                      <Icon
                        component={WarningIcon}
                        size={20}
                        color="secondary"
                      />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </DetailsItem>
            <DetailsItem
              title="Maximum instances"
              tooltip={explanations.deployments.versions.maxInstances}
            >
              <Typography>{maximum_instances}</Typography>
            </DetailsItem>
            <DetailsItem
              title="Maximum idle time"
              tooltip={explanations.deployments.versions.maxIdleTime}
            >
              <Typography>{maximum_idle_time} seconds</Typography>
            </DetailsItem>
            <DetailsItem
              title="Request retention mode"
              tooltip={explanations.deployments.versions.retentionMode}
            >
              <Typography>
                {getLabel(requestRetentionModes, request_retention_mode)}
              </Typography>
            </DetailsItem>
            <DetailsItem
              title="Request retention time"
              tooltip={explanations.deployments.versions.retentionTime}
            >
              <Typography>
                {request_retention_mode === "none"
                  ? "-"
                  : getLabel(
                      requestRetentionTimes as any,
                      request_retention_time
                    )}
              </Typography>
            </DetailsItem>
            <DetailsItem
              title="Maximum express queue size"
              tooltip={explanations.queueSize.express}
            >
              <Typography>{maximum_queue_size_express}</Typography>
            </DetailsItem>
            <DetailsItem
              title="Maximum batch queue size"
              tooltip={explanations.queueSize.batch}
            >
              <Typography>{maximum_queue_size_batch}</Typography>
            </DetailsItem>
            <DetailsItem
              title="Static IP address"
              tooltip={explanations.deployments.versions.staticIP}
            >
              {static_ip ? (
                <CheckIcon
                  fontSize="small"
                  htmlColor={theme.palette.success.main}
                />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}
            </DetailsItem>
            <DetailsItem
              title="Port forwarding"
              tooltip={explanations.deployments.versions.portForwardingShort}
            >
              {portForwarding ? (
                <Typography>
                  {portForwarding.deployment_port} →{" "}
                  {portForwarding.public_port}{" "}
                  {portForwarding.protocol?.toUpperCase()}
                </Typography>
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}
            </DetailsItem>
          </Card>
        </Grid>
        <DescriptionBlock
          description={description}
          labels={formatLabels(version?.labels ?? {})}
        />
      </Grid>
      <Dialog
        open={isLogsDialogOpen}
        onClose={() => setIsLogsDialogOpen(false)}
        // @ts-ignore
        title={
          <>
            {versionName} logs
            {/* @ts-ignore */}
            <StatusIcon label={revision?.status} status={revision?.status} />
          </>
        }
        maxWidth="md"
        dialogContentStyles={{ paddingBottom: spacing[8] }}
      >
        <Logs
          logSet={{ logs: logs }}
          autoLoading={!!logsLoading}
          logsStatus={LOADED}
          refresh={false}
          displayNoLogsMessage={false}
          isBuildingLogs={true}
        />
      </Dialog>
    </>
  );
};
