import GetResultsIcon from "@mui/icons-material/AssignmentRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import Trash from "@mui/icons-material/DeleteRounded";
import NotificationIcon from "@mui/icons-material/Notifications";
import { Box, Grid, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { DeploymentRequestSingleDetailStatus } from "libs/data/models";
import { useRetryRequest } from "libs/hooks";

import { IconButton, RetryIconSpinner } from "components/atoms";

import { RequestsLogsIcon } from "./RequestsLogsIcon";

import type { RequestParameter, RequestRow } from "./types";

interface RequestsRowActionsProps {
  rowData: RequestRow;
  allowDelete: boolean;
  allowGet?: boolean;
  allowLogs?: boolean;
  allowCreate?: boolean;
  allowUpdate?: boolean;
  logsUrl: string;
  requestParameters: RequestParameter;
  setSelectedRequest: (rowData: RequestRow) => void;
  notificationsDialogToggle: () => void;
  setIsRequestResultsDialogOpen: (isOpen: boolean) => void;
  setIsCancelRequestDialogOpen: (isOpen: boolean) => void;
  setIsDeleteDialogOpen: (isOpen: boolean) => void;
  refresh: () => void;
  tableOptions?: {
    withoutMultiSelect?: boolean;
    withoutDeleteButton?: boolean;
    withoutNotificationButton?: boolean;
    withoutCancelButton?: boolean;
  };
}

export const RequestsRowActions = ({
  rowData,
  allowDelete,
  tableOptions,
  allowGet,
  requestParameters,
  setSelectedRequest,
  notificationsDialogToggle,
  setIsRequestResultsDialogOpen,
  setIsCancelRequestDialogOpen,
  setIsDeleteDialogOpen,
  allowLogs,
  allowUpdate,
  allowCreate,
  logsUrl,
  refresh,
}: RequestsRowActionsProps) => {
  const {
    withoutDeleteButton,
    withoutNotificationButton,
    withoutCancelButton,
  } = tableOptions || {
    withoutMultiSelect: false,
    withoutDeleteButton: false,
    withoutNotificationButton: false,
    withoutCancelButton: false,
  };
  const isCompletedRequest =
    rowData?.status === DeploymentRequestSingleDetailStatus.completed;
  const isFailedRequest =
    rowData?.status === DeploymentRequestSingleDetailStatus.failed;
  const isCancelledRequest =
    rowData?.status === DeploymentRequestSingleDetailStatus.cancelled;
  const isPendingRequest =
    rowData?.status === DeploymentRequestSingleDetailStatus.pending;
  const isProcessingRequest =
    rowData?.status === DeploymentRequestSingleDetailStatus.processing;
  const isCancelledPendingRequest =
    rowData?.status === DeploymentRequestSingleDetailStatus.cancelled_pending;

  const enableSingleDelete =
    allowDelete &&
    (isCompletedRequest || isFailedRequest || isCancelledRequest);

  const { projectName } =
    useParams<{
      projectName: string;
    }>();

  const { onRetry, retrying } = useRetryRequest({
    projectName,
    requestParameters,
    batchMode: true,
  });

  const onRetryClick = useCallback(
    async (rowData) => {
      await onRetry({
        id: rowData?.id,
        deployment: rowData?.deployment,
        pipeline: rowData?.pipeline,
        version: rowData?.version,
      });
      refresh();
    },
    [onRetry, refresh]
  );

  const onNotificationsClick = useCallback(
    (rowData) => {
      setSelectedRequest(rowData);
      notificationsDialogToggle();
    },
    [notificationsDialogToggle, setSelectedRequest]
  );

  const onViewResults = useCallback(
    (event, rowData) => {
      event.stopPropagation();

      setIsRequestResultsDialogOpen(true);
      setSelectedRequest(rowData);
    },
    [setIsRequestResultsDialogOpen, setSelectedRequest]
  );

  const onCancelRequest = useCallback(
    (rowData) => {
      setSelectedRequest(rowData);
      setIsCancelRequestDialogOpen(true);
    },
    [setIsCancelRequestDialogOpen, setSelectedRequest]
  );

  const onSingleDelete = useCallback(
    (rowData) => {
      const canDelete =
        allowDelete &&
        (isCompletedRequest || isFailedRequest || isCancelledRequest);

      if (canDelete) {
        setSelectedRequest(rowData);
        setIsDeleteDialogOpen(true);
      }
    },
    [
      allowDelete,
      isCancelledRequest,
      isCompletedRequest,
      isFailedRequest,
      setIsDeleteDialogOpen,
      setSelectedRequest,
    ]
  );

  const isPendingOrProcessing =
    rowData?.status && ["pending", "processing"].includes(rowData.status);

  return (
    <Box display="flex" justifyContent="flex-end" minWidth={"215px"}>
      <Grid item xs={12}>
        {!withoutNotificationButton && (
          <Tooltip
            title={
              !isPendingOrProcessing
                ? "Cannot update notifications for finished requests"
                : "Enable notifications"
            }
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                disabled={!allowGet || !isPendingOrProcessing}
                onClick={() => onNotificationsClick(rowData)}
                icon={NotificationIcon}
                htmlColor="secondary"
              />
            </span>
          </Tooltip>
        )}

        <Tooltip title="View results">
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconButton
              disabled={!allowGet}
              onClick={(e) => onViewResults(e, rowData)}
              icon={GetResultsIcon}
              htmlColor="secondary"
            />
          </span>
        </Tooltip>

        <RequestsLogsIcon
          rowData={rowData}
          allowLogs={allowLogs}
          logsUrl={logsUrl}
        />

        <RetryIconSpinner
          disabled={!allowCreate}
          onClick={(e) => {
            e.stopPropagation();
            onRetryClick(rowData);
          }}
          spinCondition={retrying}
        />

        {!withoutCancelButton && (
          <Tooltip
            title={
              isPendingRequest || isProcessingRequest
                ? "Cancel"
                : isCancelledPendingRequest
                ? "Request is being cancelled"
                : "Request can only be cancelled when it's in pending or processing state"
            }
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                disabled={
                  // @ts-expect-error deployment doesn't exist on PipelineRequestSingleDetail
                  // and that's what we're actually checking here but ts is not happy
                  !rowData?.deployment ||
                  !allowUpdate ||
                  (!isPendingRequest && !isProcessingRequest)
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onCancelRequest(rowData);
                }}
                icon={CancelIcon}
                htmlColor="primary"
                hoverColor="primary"
              />
            </span>
          </Tooltip>
        )}

        {!withoutDeleteButton && (
          <Tooltip
            title={
              !allowDelete
                ? "You're currently not allowed to delete requests, contact an admin to get permission"
                : !enableSingleDelete
                ? "Requests in pending, processing or cancelling state cannot be deleted"
                : "Delete"
            }
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                disabled={!enableSingleDelete}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSingleDelete(rowData);
                }}
                icon={Trash}
                htmlColor="primary"
                hoverColor="primary"
              />
            </span>
          </Tooltip>
        )}
      </Grid>
    </Box>
  );
};
