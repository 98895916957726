import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { useMemo } from "react";
import * as Yup from "yup";

import {
  FormikCheckbox,
  FormikTextInput,
} from "components/molecules/FormikInputs";
import { parseErrorFromControl } from "components/molecules/FormikInputs/utils";
import validators, { yupValidators } from "libs/utilities/validators";

import { LabelChip, AddButton } from "components/atoms";

import type { FormikProps, FormikValues, FormikHelpers } from "formik";
import type { WebhookHeader } from "libs/data/models";

const KEY = "key";
const VALUE = "value";
const PROTECTED = "protected";

const initialValues: Partial<WebhookHeader> = {
  key: "",
  value: "",
  protected: false,
};

interface ControlledHeadersInputProps {
  error?: string;
  onChange: (value: WebhookHeader[]) => void;
  value: WebhookHeader[];
}

export const ControlledHeadersInput = ({
  onChange,
  value,
}: ControlledHeadersInputProps) => {
  const keys = useMemo(() => value.map((header) => header.key), [value]);

  const onHeaderAdd = (header: WebhookHeader) => {
    onChange([
      ...value,
      {
        key: header[KEY],
        value: header[VALUE],
        protected: header[PROTECTED],
      },
    ]);
  };

  const onHeaderRemove = (key: string) => () => {
    const newHeaders = value.filter((header) => header.key !== key);
    onChange(newHeaders);
  };

  const handlePressEnter =
    (submit?: () => void) =>
    (event: {
      keyCode: number;
      which: number;
      key: string;
      preventDefault: () => void;
    }) => {
      const keyCode = event.keyCode ?? event.which;
      if (event.key === "Enter" || keyCode === 13) {
        event.preventDefault();
        submit && submit();
      }
    };

  const handleSubmit = (
    header: Partial<WebhookHeader>,
    helpers: FormikHelpers<Partial<WebhookHeader>>
  ) => {
    onHeaderAdd(header as WebhookHeader);
    helpers.resetForm();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ paddingBottom: 12 }}></Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          key: Yup.string()
            .required(validators.required.message(KEY))
            .test(
              "uniqueKeys",
              yupValidators.uniqueKeys.message,
              yupValidators.uniqueKeys.test(keys)
            ),
          value: Yup.string().required(validators.required.message(VALUE)),
        })}
      >
        {(control: FormikProps<FormikValues>) => {
          const error =
            parseErrorFromControl(control, KEY) ??
            parseErrorFromControl(control, VALUE);

          return (
            <>
              <Grid item xs={5}>
                <FormikTextInput
                  control={control}
                  name={KEY}
                  label="Key"
                  placeholder={`Ex: "Authorization"`}
                  onKeyPress={handlePressEnter(control.submitForm)}
                  showErrors={false}
                />
              </Grid>
              <Grid item xs={5}>
                <FormikTextInput
                  control={control}
                  name={VALUE}
                  label="Value"
                  onKeyPress={handlePressEnter(control.submitForm)}
                  showErrors={false}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems="center">
                <FormikCheckbox
                  name={PROTECTED}
                  control={control}
                  label="Secret"
                  sx={{ alignSelf: "center" }}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 8 }}>
                <AddButton onClick={control.submitForm}>Add header</AddButton>
              </Grid>
              {!!error && (
                <Grid item xs={12}>
                  <Typography color="error" variant="caption">
                    {error}
                  </Typography>
                </Grid>
              )}
            </>
          );
        }}
      </Formik>

      <Grid item xs={12}>
        {value.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="caption">No headers specified</Typography>
          </Grid>
        )}
        <Grid container direction="row" item xs={12}>
          <Grid item xs={12} container alignItems="center" spacing={1}>
            {value.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="caption">Added headers:</Typography>
              </Grid>
            )}
            {value.map((header) => (
              <Grid item key={header.key}>
                <LabelChip
                  capital={false}
                  label={header.key}
                  value={header.protected ? "*****" : header.value}
                  onDelete={onHeaderRemove(header.key)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
