import BarChart from "@mui/icons-material/BarChart";
import Delete from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";
import { Grid } from "@mui/material";
import { useMemo } from "react";

import { spacing } from "assets/styles/theme";

import { Card } from "components/atoms";

import { DeploymentsUsageCard } from "./DeploymentsUsageCard";
import { ProjectIdCard } from "./ProjectIdCard";
import { RemoveProjectCard } from "./RemoveProjectCard";
import ProjectUsage from "../../dashboard/ProjectUsage";

export const ProjectSettinsGeneral = () => {
  const projectSettingsBlocks = useMemo(
    () => [
      {
        title: "Usage and limits",
        icon: BarChart,
        content: <ProjectUsage showChangeProjectLimitsButton />,
      },
      {
        title: "Remove Project",
        icon: Delete,
        content: <RemoveProjectCard />,
      },
    ],
    []
  );

  const usageGraphBlock = useMemo(
    () => [
      {
        title: "Project Id",
        icon: HomeIcon,
        content: <ProjectIdCard />,
      },
      {
        title: "Credit usage by deployment",
        icon: BarChart,
        content: <DeploymentsUsageCard />,
      },
    ],
    []
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} container rowGap={2}>
        {projectSettingsBlocks.map(({ content, ...props }, key) => (
          <Grid item xs={12} key={key}>
            <Card
              iconColor="primary"
              {...props}
              contentStyle={{
                marginTop: spacing[20],
              }}
            >
              {content}
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={6} container rowGap={2}>
        {usageGraphBlock.map(({ content }, key) => (
          <Grid item xs={12} key={key}>
            {content}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
