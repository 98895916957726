import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  removeSnackbar,
  useGetNotifications,
} from "store/features/notifications";

import type { SnackbarKey } from "notistack";

const Notifier = () => {
  const [displayed, setDisplayed] = useState<string[]>([]);
  const { pathname } = useLocation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const notifications = useGetNotifications();
  const dispatch = useDispatch();

  const storeDisplayed = (key: string) => {
    setDisplayed((prevDisplayed) => [...prevDisplayed, key]);
  };

  const removeDisplayed = (key: SnackbarKey) => {
    setDisplayed((prevDisplayed) =>
      prevDisplayed.filter((notificationKey) => notificationKey !== key)
    );
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);

        return;
      }

      // Do nothing if snackbar is already displayed
      if (displayed.includes(key)) {
        return;
      }
      // Display snackbar using notistack
      // except if it's an error and we're on the sign in page (we're showing it in the form)
      // or if the error is about terms and conditions (we're handling them with redirection)
      const isSignInPage =
        pathname.includes("/sign-in") && options?.variant === "error";
      const isTermsError = message?.includes("Could not authenticate");
      const isRequestAborted = message?.includes("Request aborted");
      const showNotification =
        !isSignInPage && !isTermsError && !isRequestAborted;

      if (showNotification)
        enqueueSnackbar(message, {
          key,
          ...options,
          action: (key: SnackbarKey) => (
            <IconButton
              onClick={() => closeSnackbar(key)}
              size="small"
              color="inherit"
            >
              <Close fontSize="small" />
            </IconButton>
          ),
          onClose: (event: any, reason: any, key: SnackbarKey | undefined) => {
            if (options.onClose && key) {
              options.onClose(event, reason, key);
            }
          },
          onExited: (_event: any, key: SnackbarKey) => {
            dispatch(removeSnackbar(key));
            removeDisplayed(key);
          },
        });

      // Keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [
    notifications,
    displayed,
    enqueueSnackbar,
    closeSnackbar,
    pathname,
    dispatch,
  ]);

  return null;
};

export default Notifier;
