import { useTheme } from "@mui/styles";

export const IlluRevisions = () => {
  const theme = useTheme();

  return (
    <svg
      id="e9cedb39-6f65-4384-b75a-4a0d633b244e"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 769.61192 699.92655"
    >
      <polygon
        points="757.569 699.927 0 699.927 0 697.745 757.964 697.745 757.569 699.927"
        fill={theme.palette.primary.light}
      />
      <path
        d="M777.806,396.01231H507.80547a5.00588,5.00588,0,0,1-5-5V268.03673a5.00588,5.00588,0,0,1,5-5H777.806a5.00589,5.00589,0,0,1,5,5V391.01231A5.00589,5.00589,0,0,1,777.806,396.01231ZM507.80547,265.03673a3.00328,3.00328,0,0,0-3,3V391.01231a3.00328,3.00328,0,0,0,3,3H777.806a3.00328,3.00328,0,0,0,3-3V268.03673a3.00328,3.00328,0,0,0-3-3Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.primary.light}
      />
      <circle
        cx="327.61143"
        cy="201.48753"
        r="21"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M591.30547,287.52426a3.5,3.5,0,0,0,0,7h165a3.5,3.5,0,0,0,0-7Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M591.30547,308.52426a3.5,3.5,0,0,0,0,7h71a3.5,3.5,0,0,0,0-7Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M526.69791,342.09717c-2.66237,0-4.82892,1.57031-4.82892,3.5s2.16655,3.5,4.82892,3.5H754.34715c2.66238,0,4.82893-1.57031,4.82893-3.5s-2.16655-3.5-4.82893-3.5Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M526.69791,363.09717c-2.66237,0-4.82892,1.57031-4.82892,3.5s2.16655,3.5,4.82892,3.5H754.34715c2.66238,0,4.82893-1.57031,4.82893-3.5s-2.16655-3.5-4.82893-3.5Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M979.806,233.01231H709.80547a5.00588,5.00588,0,0,1-5-5V105.03673a5.00588,5.00588,0,0,1,5-5H979.806a5.00589,5.00589,0,0,1,5,5V228.01231A5.00589,5.00589,0,0,1,979.806,233.01231ZM709.80547,102.03673a3.00328,3.00328,0,0,0-3,3V228.01231a3.00328,3.00328,0,0,0,3,3H979.806a3.00328,3.00328,0,0,0,3-3V105.03673a3.00328,3.00328,0,0,0-3-3Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.primary.light}
      />
      <circle
        cx="529.61143"
        cy="38.48753"
        r="21"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M793.30547,124.52426a3.5,3.5,0,0,0,0,7h165a3.5,3.5,0,0,0,0-7Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M793.30547,145.52426a3.5,3.5,0,0,0,0,7h71a3.5,3.5,0,0,0,0-7Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M728.69791,179.09717c-2.66237,0-4.82892,1.57031-4.82892,3.5s2.16655,3.5,4.82892,3.5H956.34715c2.66238,0,4.82893-1.57031,4.82893-3.5s-2.16655-3.5-4.82893-3.5Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M728.69791,200.09717c-2.66237,0-4.82892,1.57031-4.82892,3.5s2.16655,3.5,4.82892,3.5H956.34715c2.66238,0,4.82893-1.57031,4.82893-3.5s-2.16655-3.5-4.82893-3.5Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M979.806,559.01231H709.80547a5.00588,5.00588,0,0,1-5-5V431.03673a5.00588,5.00588,0,0,1,5-5H979.806a5.00589,5.00589,0,0,1,5,5V554.01231A5.00589,5.00589,0,0,1,979.806,559.01231ZM709.80547,428.03673a3.00328,3.00328,0,0,0-3,3V554.01231a3.00328,3.00328,0,0,0,3,3H979.806a3.00328,3.00328,0,0,0,3-3V431.03673a3.00328,3.00328,0,0,0-3-3Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.primary.light}
      />
      <circle
        cx="529.61143"
        cy="364.48753"
        r="21"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M793.30547,450.52426a3.5,3.5,0,0,0,0,7h165a3.5,3.5,0,0,0,0-7Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M793.30547,471.52426a3.5,3.5,0,0,0,0,7h71a3.5,3.5,0,0,0,0-7Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M728.69791,505.09717c-2.66237,0-4.82892,1.57031-4.82892,3.5s2.16655,3.5,4.82892,3.5H956.34715c2.66238,0,4.82893-1.57031,4.82893-3.5s-2.16655-3.5-4.82893-3.5Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M728.69791,526.09717c-2.66237,0-4.82892,1.57031-4.82892,3.5s2.16655,3.5,4.82892,3.5H956.34715c2.66238,0,4.82893-1.57031,4.82893-3.5s-2.16655-3.5-4.82893-3.5Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#e6e6e6"
      />
      <path
        d="M331.46871,502.39851l-9.233,13.90718a11.90547,11.90547,0,0,0,4.72248,16.29012h0a11.90548,11.90548,0,0,0,17.5161-9.342l-1.51509-12.89132,7.11083-83.58177,12.12939-54.97613-25.1834-7.14947-11.72211,61.82257Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#9f616a"
      />
      <path
        d="M461.69382,766.161,437.2887,767.2221l-54.78719-187.721L322.61689,768.6723l-23.27016-.38911,37.60773-221.79178c-11.06488-30.31918-3.88368-58.01451,13.26389-84.27659l2.57859-16.38424,66.4532-1.18049,6.24482,62.153Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M444.73147,799.54408c3.43773,1.569,6.74208-11.51838,9.21708-8.29293,9.573,12.47553,28.2134,8.16962,27.569,6.18413a5.07028,5.07028,0,0,0,3.19583-6.60734h0a5.0703,5.0703,0,0,0-3.51774-3.13861l-8.62612-2.15653L459.02527,761.7136l-14.76084-1.86817-.37282.60971a38.059,38.059,0,0,0-4.0785,31.12072C441.04066,795.50911,442.71156,798.62221,444.73147,799.54408Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M304.10119,799.54408c3.43773,1.569,6.74207-11.51838,9.21708-8.29293,9.573,12.47553,28.2134,8.16962,27.569,6.18413a5.07028,5.07028,0,0,0,3.19583-6.60734h0a5.07029,5.07029,0,0,0-3.51773-3.13861l-8.62613-2.15653L318.395,761.7136l-14.76084-1.86817-.37283.60971a38.05907,38.05907,0,0,0-4.0785,31.12072C300.41037,795.50911,302.08128,798.62221,304.10119,799.54408Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M319.5,412.84919l19.19988-65.54036a17.97906,17.97906,0,0,1,21.09765-14.19092h0a17.97906,17.97906,0,0,1,14.19092,21.09766l-15.69728,59.85364C346.91816,402.09344,333.84973,402.71226,319.5,412.84919Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M489.21633,351.82267l-36.36191,8.021,1.5634-28.92287h29.70457l19.1417-58.01277-.25119-15.01287A9.7636,9.7636,0,0,1,511.567,245.485h0a9.7636,9.7636,0,0,1,9.94664,13.23286l-6.54217,17.7925L506.01,330.92081v0A21.40433,21.40433,0,0,1,489.21633,351.82267Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#9f616a"
      />
      <circle cx="174.73361" cy="179.68279" r="31.26796" fill="#9f616a" />
      <path
        d="M395.79039,345.77309l-46.12024.7817c7.36535-23.09586,12.42445-44.69022,11.72548-62.53592h35.17646A306.82039,306.82039,0,0,0,395.79039,345.77309Z"
        transform="translate(-215.19404 -100.03673)"
        fill="#9f616a"
      />
      <path
        d="M425.88581,450.12992l-75.60666,11.49764c-11.94391-34.66189-12.29556-74.66383-5.92259-104.26765a48.67768,48.67768,0,0,1,13.13056-38.94627h0l36.73985,3.90849h0a23.51105,23.51105,0,0,1,17.66713,17.08469c14.17535,19.65192,24.56025,38.247,13.07514,50.74412Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M471.6152,366.09727l-68.00784-6.25359a17.97906,17.97906,0,0,1-17.97906-17.97906v0a17.97906,17.97906,0,0,1,17.97906-17.97906l61.75425,3.90849C455.79333,341.25559,458.91078,353.96183,471.6152,366.09727Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M347.32476,338.28841l-23.09918-.59354c3.68709-32.29224,5.60728-68.683,24.44676-87.38923,2.3972-2.38025,4.549-4.84521,6.69665-7.31439,4.27671-4.91691,13.34-7.73949,22.60615-6.66806l4.54044.525c14.9056,1.72351,46.1707,12.41042,45.26513,21.746-.46112,4.75367-.7723,13.306-12.08568,13.41362-9.137,12.25945-25.47543,19.06639-18.65353,30.71223,13.64877,12.17515-4.111,21.82749-18.433,29.10063C369.82787,336.27972,358.68951,338.58043,347.32476,338.28841Z"
        transform="translate(-215.19404 -100.03673)"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
