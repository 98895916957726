import Plus from "@mui/icons-material/AddBoxRounded";
import GetResultsIcon from "@mui/icons-material/AssignmentRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { sortBy } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { CopyBlock, dracula } from "react-code-blocks";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { PROJECT_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { useMetricsDelete } from "libs/data/customized/metrics";
import { useMetricsList } from "libs/data/endpoints/metrics/metrics";
import { useDeviceDetect } from "libs/hooks";
import { useGetCurrentOrganization } from "store/features";
import { useGetPermissions } from "store/features/permissions";

import {
  Chip,
  DeleteDialog,
  DetailsItem,
  Dialog,
  ExternalLink,
  IconButton,
  PrimaryButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import { metricSnippetText } from "./constants";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { BaseColumn } from "components/molecules/BaseTable";
import type { MetricDetail } from "libs/data/models";

export const MetricsOverview = () => {
  const [isDeleteDialogOpen, setIseDeleteDialogOpen] = useState(false);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isSnippetDialogOpen, setSnippetDialogOpen] = useState(false);
  const [selectedMetric, setSelectedMetric] =
    useState<MetricDetail | null>(null);

  const { isMobile } = useDeviceDetect();
  const { projectName } = useParams<{ projectName: string }>();
  const { state } = useLocation();
  const { data: metrics } = useMetricsList(projectName);
  const baseUrl = useContext(BaseUrlContext);
  const theme = useTheme() as AppThemeProps;
  const history = useHistory();
  const match = useRouteMatch();

  const organization = useGetCurrentOrganization();
  const [currentPermissions] = useGetPermissions();

  const deleteMetric = useMetricsDelete(projectName);

  const selectedMetricLabel = useMemo(
    () => selectedMetric?.labels?.[0],
    [selectedMetric]
  );

  useEffect(() => {
    if (state) {
      setSelectedMetric(state as MetricDetail);
      setSnippetDialogOpen(true);
      // avoid rerenders with window history change
      window.history.replaceState({}, "");
    }
  }, [state]);

  const columns = [
    {
      title: "Name",
      field: "name",
      nowrap: true,
      width: "25%",
      render: (rowData: MetricDetail) => (
        <Typography variant="h5" color={theme.palette.table.nameColumn}>
          {rowData.name}
        </Typography>
      ),
    },
    !isMobile && {
      title: "Description",
      field: "description",
      nowrap: true,
      width: "40%",
      render: (rowData: MetricDetail) => (
        <Typography
          variant="body1"
          maxWidth={spacing[440]}
          noWrap
          textOverflow="ellipsis"
        >
          {rowData.description}
        </Typography>
      ),
    },
    {
      title: "Type",
      field: "custom",
      width: "12%",
      render: ({ custom }: MetricDetail) => (
        <Chip
          label={custom ? "Custom" : "Default"}
          sx={{
            backgroundColor: custom
              ? theme.palette.specials.violet.bright
              : theme.palette.success.main,
            color: theme.palette.neutrals[0],
            height: spacing[30],
            width: spacing[64],
          }}
        />
      ),
    },
    {
      title: "Unit",
      width: "5%",
      field: "unit",
    },
    {
      sorting: false,
      disableClick: true,
      nowrap: true,
      align: "right",
      cellStyle: { fontSize: "0.9em" },
      render: (rowData: MetricDetail) => {
        const { custom, name } = rowData;

        return (
          <Box display="flex" justifyContent="flex-end" minWidth={"170px"}>
            {custom && (
              <IconButton
                tooltip="View snippet"
                htmlColor="secondary"
                hoverColor="secondary"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setSnippetDialogOpen(true);
                  setSelectedMetric(rowData);
                }}
                icon={IntegrationInstructionsIcon}
              />
            )}
            <IconButton
              tooltip={custom ? "Edit" : "Cannot edit default metrics"}
              htmlColor="secondary"
              hoverColor="secondary"
              disabled={
                !custom ||
                !currentPermissions[PROJECT_PERMISSIONS["metrics_update"]]
              }
              onClick={() => history.push(`${baseUrl}/metrics/${name}/edit`)}
              icon={Edit}
            />

            <IconButton
              tooltip="View details"
              htmlColor="secondary"
              hoverColor="secondary"
              disabled={!currentPermissions[PROJECT_PERMISSIONS["metrics_get"]]}
              onClick={() => {
                setIsDetailsDialogOpen(true);
                setSelectedMetric(rowData);
              }}
              icon={GetResultsIcon}
            />

            <IconButton
              htmlColor="primary"
              tooltip={custom ? "Delete" : "Cannot delete default metrics"}
              disabled={
                !custom ||
                !currentPermissions[PROJECT_PERMISSIONS["metrics_delete"]]
              }
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setIseDeleteDialogOpen(true);
                setSelectedMetric(rowData);
              }}
              icon={Trash}
            />
          </Box>
        );
      },
    },
  ].filter(Boolean);

  return (
    <>
      <BreadcrumbsItem to={match.url}>Metrics</BreadcrumbsItem>
      <BaseTable
        data={sortBy(metrics, "name")}
        columns={columns as BaseColumn[]}
        onRowClick={(_event, row) => {
          if (row) {
            setIsDetailsDialogOpen(true);
            setSelectedMetric(row);
          }
        }}
        header={
          <PrimaryButton
            size="small"
            startIcon={<Plus />}
            onClick={() => history.push(`${baseUrl}/metrics/create`)}
            disabled={
              !currentPermissions[PROJECT_PERMISSIONS["metrics_create"]] ||
              organization?.status !== "active"
            }
          >
            Create custom metric
          </PrimaryButton>
        }
      />
      <Dialog
        open={isDetailsDialogOpen}
        onClose={() => {
          setIsDetailsDialogOpen(false);
        }}
        title={`Metric Details - ${selectedMetric?.name}`}
      >
        <Box
          display="flex"
          flexDirection="column"
          minHeight={spacing[128]}
          justifyContent="space-between"
          paddingY={spacing[8]}
          gap={spacing[4]}
        >
          <DetailsItem title="Type">{selectedMetric?.metric_type}</DetailsItem>
          <DetailsItem title="Unit">{selectedMetric?.unit}</DetailsItem>
          <DetailsItem title="Labels">
            {selectedMetric?.labels?.map((label) => (
              <Chip
                label={label}
                key={label}
                sx={{
                  backgroundColor: theme.palette.specials.blue.bright,
                  color: theme.palette.neutrals[0],
                  marginBottom: spacing[2],
                }}
              />
            ))}
          </DetailsItem>
          <DetailsItem title="Description">
            {selectedMetric?.description}
          </DetailsItem>
        </Box>
      </Dialog>
      <Dialog
        open={isSnippetDialogOpen}
        onClose={() => {
          setSnippetDialogOpen(false);
          setSelectedMetric(null);
        }}
        title="Start logging data for this metric"
      >
        <Box
          display="flex"
          flexDirection="column"
          minHeight={spacing[128]}
          justifyContent="space-between"
          paddingY={spacing[8]}
          gap={spacing[4]}
        >
          <Typography textAlign="center" marginBottom={spacing[16]}>
            Your new metric was successfully created. You can now start logging
            data to this metric. You can use the code snippet below for logging
            data. Use this inside a deployment or a training run.
            <br />
            <ExternalLink href={DOC_LINKS.CUSTOM_METRICS}>
              Check our documentation
            </ExternalLink>
          </Typography>

          <CopyBlock
            {...{
              text: metricSnippetText(
                projectName,
                selectedMetric?.name,
                selectedMetricLabel
              ),
              showLineNumbers: true,
              language: "python",
              codeBlock: true,
              theme: dracula,
              wrapLines: true,
            }}
          />
          <Grid
            display="flex"
            justifyContent="flex-end"
            marginTop={spacing[16]}
          >
            <PrimaryButton
              onClick={() => {
                setSelectedMetric(null);
                setSnippetDialogOpen(false);
              }}
            >
              Got it
            </PrimaryButton>
          </Grid>
        </Box>
      </Dialog>
      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={() => {
          setIseDeleteDialogOpen(false);
        }}
        onDelete={() => deleteMetric(selectedMetric?.name as string)}
      >
        Are you sure you want to delete <b>{selectedMetric?.name}</b> metric?{" "}
      </DeleteDialog>
    </>
  );
};
