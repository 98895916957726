import { Grid } from "@mui/material";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { useGoogleAnalytics } from "libs/hooks";

import { Card } from "components/atoms";

import { ObjectsOverview } from "./ObjectsOverview";
import { PipelineDiagram } from "./PipelineDiagram";
import { PipelineVersionInfo } from "./PipelineVersionInfo";

import type { FC } from "react";

export const PipelineVersionGeneral: FC<React.PropsWithChildren<unknown>> =
  () => {
    useGoogleAnalytics();
    const match = useRouteMatch();

    return (
      <Grid xs={12}>
        <BreadcrumbsItem to={match.url}>General</BreadcrumbsItem>
        <Grid container direction="column">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PipelineVersionInfo />
            </Grid>

            <Grid item xs={12} md={6}>
              <Card title="Objects">
                <ObjectsOverview />
              </Card>
            </Grid>
          </Grid>

          <Grid item style={{ paddingTop: spacing[16] }} xs={12}>
            <PipelineDiagram />
          </Grid>
        </Grid>
      </Grid>
    );
  };
