import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import {
  FIELD_DESCRIPTION,
  FIELD_NAME,
  FIELD_TRAINING_SCRIPT,
} from "libs/constants/fields";
import { useDeploymentVersionRequestsGet } from "libs/data/endpoints/deployment-requests/deployment-requests";
import { useInterval } from "libs/hooks";
import {
  calculateDuration,
  DATE_TIME_FORMAT,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { LOADED } from "libs/utilities/request-statuses";
import { formatStatusLabel } from "libs/utilities/statuses";
import { useGetOrganizationFeatures } from "store/features";

import {
  Alert,
  Card,
  DetailsItem,
  Dialog,
  DialogHeader,
  DialogHeaderTitle,
  Link,
  Loader,
  NoData,
  SecondaryButton,
  StatusIcon,
  Switch,
} from "components/atoms";
import { DescriptionBlock } from "components/molecules";

import { LOG_LIMIT, MAX_LOG_ROWS } from "./constants";
import { Logs } from "../../../../logs";
import { loadLogs } from "../../../../logs/utils";
import {
  destructFilePath,
  mapStorageLinkToAppLink,
} from "../../../../storage/utils";
import { TRAINING_DEPLOYMENT } from "../../../constants";

import type { LogRecord } from "../../../../logs/types";
import type { LogsCreate } from "libs/data/models";

export const RunDetailsGeneral = () => {
  const [isLogsDialogOpen, setIsLogsDialogOpen] = useState(false);
  const [autoLoading, setAutoLoading] = useState(true);
  const [logs, setLogs] = useState<LogRecord[]>([]);
  const organizationFeatures = useGetOrganizationFeatures();
  const { organizationName, projectName, experimentName, runId } =
    useParams<{
      organizationName: string;
      projectName: string;
      experimentName: string;
      runId: string;
    }>();
  const { data: runDetails, mutate } = useDeploymentVersionRequestsGet(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName,
    runId
  );

  const isRunning = useMemo(
    () =>
      runDetails?.status === "processing" ||
      runDetails?.status === "cancelled_pending",
    [runDetails?.status]
  );

  const isCompleted = useMemo(
    () => !!runDetails?.time_completed,
    [runDetails?.time_completed]
  );
  const isPending = useMemo(
    () => runDetails?.status === "pending",
    [runDetails?.status]
  );
  const scriptPath = useMemo(
    () => runDetails?.request_data?.[FIELD_TRAINING_SCRIPT] ?? "",
    [runDetails]
  );
  const runName = useMemo(
    () => runDetails?.request_data?.[FIELD_NAME] ?? "",
    [runDetails]
  );

  const logsAreExpired = useMemo(
    () =>
      moment().subtract(
        organizationFeatures?.max_retention_logs || 0,
        "seconds"
      ) > moment(runDetails?.time_started),
    [organizationFeatures, runDetails?.time_started]
  );

  const loadRunLogs = useCallback(
    async (params: LogsCreate, stopLoading = true) => {
      const newLogs = await loadLogs(projectName, {
        ...params,
        filters: {
          deployment_name: TRAINING_DEPLOYMENT,
          deployment_version: experimentName,
          deployment_request_id: runId,
        },
        limit: LOG_LIMIT,
      });
      if (newLogs.length) setLogs(newLogs);
      if (stopLoading) setAutoLoading(false);
    },
    [experimentName, projectName, runId]
  );

  const loadPreviousRunLogs = useCallback(async () => {
    const newLogs = await loadLogs(projectName, {
      id: logs?.[0]?.id,
      date_range: -86400,
      filters: {
        deployment_name: TRAINING_DEPLOYMENT,
        deployment_version: experimentName,
        deployment_request_id: runId,
      },
      limit: LOG_LIMIT,
    });

    // remove the newest logs if we have too much rows
    if (newLogs?.length) {
      const appendedLogs = [...newLogs, ...logs];
      setLogs(
        appendedLogs.length > MAX_LOG_ROWS
          ? appendedLogs.slice(0, MAX_LOG_ROWS)
          : appendedLogs
      );
    }
  }, [experimentName, logs, projectName, runId]);

  const loadNextRunLogs = useCallback(async () => {
    const newLogs = await loadLogs(projectName, {
      id: logs?.[logs?.length - 1]?.id,
      date_range: 86400,
      filters: {
        deployment_name: TRAINING_DEPLOYMENT,
        deployment_version: experimentName,
        deployment_request_id: runId,
      },
      limit: LOG_LIMIT,
    });

    // remove the oldest logs if we have too much rows
    if (newLogs?.length) {
      const appendedLogs = [...logs, ...newLogs];
      setLogs(
        appendedLogs.length > MAX_LOG_ROWS
          ? appendedLogs.slice(LOG_LIMIT)
          : appendedLogs
      );
    }
  }, [experimentName, logs, projectName, runId]);

  useEffect(() => {
    // fetch last 100 logs if the run has completed status
    const date = runDetails?.time_completed;
    if (logs.length === 0 && date) {
      loadRunLogs({
        date,
      });
    }
  }, [loadRunLogs, logs, runDetails]);

  useInterval(
    () => {
      if (isRunning || isPending) mutate();
      if (autoLoading)
        loadRunLogs(
          {
            date: moment().toISOString(),
          },
          false
        );
      if (autoLoading && isCompleted) {
        setAutoLoading(false);
      }
    },
    [isRunning, isPending, mutate, autoLoading, loadRunLogs, isCompleted],
    CONTINUOUS_REQUEST_DELAY,
    false
  );

  return (
    <>
      {runDetails?.error_message && (
        <Alert severity="error">Error: {runDetails?.error_message}</Alert>
      )}
      <Grid container spacing={2} height="100%">
        <Grid item xs={12} md={6}>
          <Card>
            <DetailsItem title="Status">
              <StatusIcon
                label={runDetails?.status}
                status={formatStatusLabel(runDetails?.status)}
              />
            </DetailsItem>

            <DetailsItem title="Created">
              <Typography>
                {runDetails?.time_created
                  ? getTzAwareDate(runDetails?.time_created).format(
                      DATE_TIME_FORMAT
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Started">
              <Typography>
                {runDetails?.time_started
                  ? getTzAwareDate(runDetails?.time_started).format(
                      DATE_TIME_FORMAT
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Completed">
              <Typography>
                {runDetails?.time_completed
                  ? getTzAwareDate(runDetails?.time_completed).format(
                      DATE_TIME_FORMAT
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Duration">
              <Typography>
                {runDetails?.time_started && runDetails?.time_completed
                  ? calculateDuration(
                      runDetails?.time_started,
                      runDetails?.time_completed
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Script location">
              {scriptPath && (
                <Tooltip title={scriptPath}>
                  <Box>
                    <Link
                      to={{
                        ...location,
                        state: {
                          selectedFile: destructFilePath(scriptPath)?.file,
                        },
                        pathname: mapStorageLinkToAppLink(
                          scriptPath,
                          organizationName,
                          projectName
                        ),
                      }}
                      style={{
                        display: "inline-block",
                        width: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {scriptPath}
                    </Link>
                  </Box>
                </Tooltip>
              )}
            </DetailsItem>
            <DetailsItem title="Output artefact">
              {runDetails?.result?.artifact && (
                <Tooltip title={runDetails?.result?.artifact}>
                  <Link
                    to={{
                      ...location,
                      state: {
                        selectedFile: destructFilePath(
                          runDetails.result.artifact
                        )?.file,
                      },
                      pathname: mapStorageLinkToAppLink(
                        runDetails.result.artifact,
                        organizationName,
                        projectName
                      ),
                    }}
                    style={{
                      display: "inline-block",
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {runDetails?.result?.artifact}
                  </Link>
                </Tooltip>
              )}
            </DetailsItem>
          </Card>
        </Grid>

        <DescriptionBlock
          description={runDetails?.request_data?.[FIELD_DESCRIPTION]}
        />
        <Grid item xs={12}>
          <Card
            title="Logs"
            actions={
              <Grid display="flex" item>
                <Switch
                  checked={autoLoading && runDetails?.status !== "completed"}
                  onChange={() => setAutoLoading(!autoLoading)}
                  disabled={runDetails?.status === "completed"}
                  label="Auto load logs"
                  labelPlacement="start"
                />
                <SecondaryButton
                  startIcon={<FullscreenIcon />}
                  onClick={() => setIsLogsDialogOpen(true)}
                  disabled={isPending}
                  style={{
                    flex: "none",
                    marginLeft: spacing[4],
                  }}
                >
                  Full screen
                </SecondaryButton>
              </Grid>
            }
            contentStyle={{
              maxHeight: "50vh",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {logs?.length ? (
              <Logs
                logSet={{ logs: logs }}
                autoLoading={autoLoading}
                logsStatus={LOADED}
                refresh={false}
                displayNoLogsMessage={false}
                onOlderClick={autoLoading ? undefined : loadPreviousRunLogs}
                onNewerClick={
                  autoLoading || isCompleted ? undefined : loadNextRunLogs
                }
                isBuildingLogs
              />
            ) : isRunning ? (
              <Loader />
            ) : (
              (isPending || logs.length === 0) && (
                <Box display="flex" width="100%" justifyContent="center">
                  <NoData
                    text={
                      logsAreExpired
                        ? `Sorry, the logs for this run appear to have expired.
                          Need a longer retention time for your logs? Please contact our sales team.`
                        : "No logs"
                    }
                  />
                </Box>
              )
            )}
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={isLogsDialogOpen}
        onClose={() => setIsLogsDialogOpen(false)}
        fullWidth
        maxWidth={false}
        dialogBodyStyles={{
          paddingTop: 0,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "200px",
          maxHeight: "80vh",
        }}
        Header={
          <DialogHeader
            header={
              <Box display="flex" justifyContent="space-between">
                <DialogHeaderTitle>{runName} logs</DialogHeaderTitle>
                <StatusIcon
                  label={runDetails?.status}
                  status={formatStatusLabel(runDetails?.status)}
                />
              </Box>
            }
          />
        }
      >
        {logs?.length ? (
          <Logs
            logSet={{ logs: logs }}
            autoLoading={false}
            logsStatus={LOADED}
            refresh={false}
            displayNoLogsMessage={false}
            onOlderClick={autoLoading ? undefined : loadPreviousRunLogs}
            onNewerClick={autoLoading ? undefined : loadNextRunLogs}
            isBuildingLogs
          />
        ) : (
          <Loader />
        )}
      </Dialog>
    </>
  );
};
